import React, {
  useState,
  useRef,
  useEffect,
  memo,
  useMemo,
  MutableRefObject,
  ReactElement,
} from "react";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
import MuiRadio from "@mui/material/Radio";
import Card from "@mui/material//Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

// UX
import ModConsentSuccess from "../bplus-lib/register/ModConsentSuccess";
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import CONFIG from "config/config"
import config from "config/config";

// Types
type HospitalConsentContentProps = {
  apiToken: string;
  controller: any;
  match: any;
  patientData: any;

  // state
  selectHospital: any;
  selectHospitalCode: any;
  selectHospitalName: any;
  isTelemed?: boolean;
  isTelemedFinished?: boolean;
  publishedDiagRule?: string | number;
  consentFormParams?: Record<string, any> | null;
  organizationName?: string;
  consentsList: any;
  // Element
  menuBar: ReactElement;
  // config
  hideAgreeButton?: boolean;
  modalOnRefuse?: boolean;

  // callback
  noApiToken: any;
  onFormLanguageChange: (lang: string) => any;
  onSetBackToApp: (value: boolean) => any;
  onGoToTelemedPage?: (params?: any) => any;
  onFormFinished?: Function;
  onHistoryPushState?: (params: any) => any;
};

// Images
const IMAGES = {
  cancel_hn: "/images/telephar/cancel-hn.png",
  consent: "/images/register/consent.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  light_grey: "rgba(196, 196, 196, 1)",
  very_light_grey: "rgba(121, 120, 120, 0.15)",
};

export const cardStyles = {
  borderRadius: "8px !important",
  boxShadow: "none !important",
  border: `1px solid ${COLORS.very_light_grey} !important`,
  backgroundColor: "transparent !important",
  width: "100%",
  margin: "1rem 0",
  "& .MuiCardActionArea-focusHighlight": {
    backgroundColor: "white",
  },
  "& .MuiCardContent-root": {
    padding: "14px 16px",
    "& > .header": {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      alignItems: "center",
    },
  },
  "&.active": {
    border: `1px solid ${COLORS.blue} !important`,
  },
};

const buttonStyles = {
  fontSize: "16px",
  fontWeight: "bold",
  color: "white",
  textTransform: "unset",
  backgroundColor: COLORS.blue,
  "&:hover": {
    backgroundColor: COLORS.blue,
  },
  borderRadius: "15px",
  padding: "8px 0",
  boxShadow: "none",
  margin: "5px",
  width: "50%",
} as CSSProperties;

const checkboxPrimaryStyles = {
  color: COLORS.blue,
  marginTop: "0 !important",
  marginLeft: "17px !important",
  width: "max-content",
  "& .MuiCheckbox-root": {
    marginTop: "-2px",
    transform: "scale(1.2)",
    "&.Mui-checked .MuiSvgIcon-root": {
      color: COLORS.blue,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  button: buttonStyles,
  checkbox: {
    "& .MuiSvgIcon-root": { fontSize: 28 },
  },
  card: cardStyles,
  checkbox_primary: checkboxPrimaryStyles,
}));

const LANGUAGE = {
  TH: "th-TH",
  EN_US: "en-US",
};

const OPTIONS_LABEL = {
  consent: ["ยอมรับ", "ยินยอม", "consent", "รับทราบ", "acknowledged"],
  refuse: ["ไม่ยินยอม", "Refuse", "ไม่ยอมรับ", "refuse", "acknowledged"],
};

const HospitalConsentContent = (props: HospitalConsentContentProps) => {
  const classes = useStyles();

  const [answer, setAnswer] = useState<Record<string, any>>({});
  const [updateConsent, setUpdateConsent] = useState<number>(0);
  const [diagRuleContent, setDiagRuleContent] = useState<Record<string, any>>(
    {}
  );
  const [showModal, setShowModal] = useState<{
    index?: number;
    label?: string;
    modal?: boolean;
    telemed?: boolean;
    choice?: string;
    options?: any[];
  }>({});
  const [openModal, setOpenModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [modError, setModError] = useState<any>(null);
  // -const [modalDetail, setModalDetail] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [formLanguage, setFormLanguage] = useState<any>(null);
  const [currentForm, setCurrentForm] = useState<Record<string, any>>({});
  const [diagFormId, setDiagFormId] = useState(null);
  const [editAnswer, setEditAnswer] = useState<Record<string, any>>({});
  const [userLang, setUserLang] = useState<string | null>(null);
  const [formLangText, setFormLangText] = useState("th-TH");

  const [isBottom, setIsBottom] = useState<boolean>(false);

  const isMounted = useRef(true);
  const consentFormRef = useRef() as MutableRefObject<HTMLDivElement>;
  const isBottomRef = useRef<boolean>(false);
  const isAgreeAllRef = useRef<boolean>(false);

  const apiToken = Cookies.get("apiToken");
  const cookiesFormLang = Cookies.get("formLang");
  const history = useHistory<any>();

  useEffect(() => {
    const bodyRef = consentFormRef.current?.closest("body") as HTMLBodyElement;

    if (!bodyRef) {
      return;
    }

    const handleScroll = () => {
      const isBottom =
        window.scrollY + bodyRef.offsetHeight + 15 >= bodyRef.scrollHeight;

      if (isBottom && !isBottomRef.current) {
        isBottomRef.current = true;
        setIsBottom(true);
      }
    };

    handleScroll();

    bodyRef.onscroll = handleScroll;
    return () => {
      bodyRef.onscroll = null;
      isBottomRef.current = false;
      setIsBottom(false);
    };
  }, [props.match.params.page]);

  useEffect(() => {
    handleSetDefaultLang();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.onSetBackToApp) {
      props.onSetBackToApp(false);
    }
  }, []);

  useEffect(() => {
    if (!props.apiToken && !apiToken) {
      props.noApiToken();
    }
  }, [props.apiToken, apiToken]);

  useEffect(() => {
    const fetchData = async () => {
      if (props.apiToken || apiToken) {
        setIsLoading(true);

        const promiseArr = [
          getPublishDiagRuleDetail({
            publishDiagRuleId: props.match.params.id,
          }),
        ];
        // มี published diag rule
        if (props.match?.params?.id) {
          promiseArr.push(getProxyPatientHasDivision());
        }

        const [diagRuleId, division] = await Promise.all(promiseArr);

        // มี division
        if (division?.id) {
          const diagForm = await getPublishDiagRuleCheck(
            division.id,
            diagRuleId
          );
          // ถ้ามี diag form เก่า ให้จำเลขไว้ 
          if (diagForm) {
            setUpdateConsent(diagForm);
            await getDiagFormDetail(diagForm);
          } else {
            setUpdateConsent(0);
            setEditAnswer({});
          }
        }

        setIsLoading(false);
      }
    };

    if (props.consentFormParams?.pathname === history.location.pathname) {
      const data = props.consentFormParams;

      setCurrentForm(data.currentForm);
      setEditAnswer(data.editAnswer);
      setDiagRuleContent(data.diagRuleContent);
      setFormLangText(data.formLangText);
      setDiagFormId(data.diagFormId);
      setUserLang(data.userLang);
      setFormLangText(data.formLangText);
      setIsBottom(data.isBottom);

      setTimeout(() => setAnswer(data.answer), 200);

      isBottomRef.current = data.isBottom;

      console.log(data);

      return props.onGoToTelemedPage?.();
    }

    fetchData();
  }, [props.match.params.id, props.apiToken, apiToken]);

  useEffect(() => {
    setShowModal({});
  }, [props.match.params.page]);

  useEffect(() => {
    if (cookiesFormLang) {
      return setFormLanguage(cookiesFormLang);
    }
    if (Object.keys(diagRuleContent).length > 1) {
      let obj = Object.values(diagRuleContent).find(
        (item) => item.parent_id === diagRuleContent[0].id
      );
      if (obj) {
        setFormLanguage(obj.id);
      }
    } else {
      setCurrentForm(diagRuleContent[0]);
    }
  }, [diagRuleContent, cookiesFormLang]);

  useEffect(() => {
    if (Object.keys(diagRuleContent).length > 1) {
      let obj = Object.values(diagRuleContent).find(
        (item) => item.id == formLanguage
      );
      if (obj) {
        setCurrentForm(obj);
        props.onFormLanguageChange(obj.label);
        let language = "th-TH";
        if (
          obj.label &&
          typeof obj.label === "string" &&
          ["en", "en-us", "eng", "english"].includes(obj.label.toLowerCase())
        ) {
          language = "en-US";
        }
        setFormLangText(language);
      }
    } else {
      setCurrentForm(diagRuleContent[0]);
    }
  }, [formLanguage, diagRuleContent]);

  useEffect(() => {
    let firstAnswer = editAnswer?.[0] ? editAnswer[0].answer : null;
    const editAnswerValues = Object.values(editAnswer);
    let obj =
      editAnswerValues.find((item) => item.label === firstAnswer) ||
      editAnswerValues.find((item) => item.selected);

    if (currentForm?.extra_items && (!obj || currentForm.id != obj.id)) {
      let defaultAnswer: Record<string, any> = {};
      currentForm.extra_items.forEach((item: any, index: number) => {
        // item.type === "select" && item.default
        if (item.type) {
          defaultAnswer[item.label] = {
            value: item.default,
            type: item.type,
          };
        }
      });
      setAnswer(defaultAnswer);
    } else {
      handleSetEditAnswer();
    }
  }, [currentForm, editAnswer]);

  useEffect(() => {
    if (Object.keys(editAnswer).length === 0) {
      if (userLang === "th-TH") {
        let obj = Object.values(diagRuleContent).find(
          (item) => item.label === "ไทย"
        );
        if (obj) {
          return setFormLanguage(obj.id);
        }
      } else if (userLang === "en-US") {
        let obj = Object.values(diagRuleContent).find(
          (item) => item.label.toLowerCase() === "English".toLowerCase()
        );
        if (obj) {
          return setFormLanguage(obj.id);
        }
      }
    } else {
      let obj = Object.values(diagRuleContent).find(
        (item) => editAnswer[0] && item.label === editAnswer[0].answer
      );
      if (obj) {
        setFormLanguage(obj.id);
      }
    }
  }, [diagRuleContent, userLang, editAnswer]);

  useEffect(() => {
    if (isAgreeAllRef.current) {
      isAgreeAllRef.current = false;
      handleFinishForm();
    }
  }, [answer]);

  useEffect(() => {
    if (showModal.index || showModal.index === 0) {
      // -let arrAfterItem = currentForm?.extra_items.slice(showModal.index + 1);
      // -let modalObj = arrAfterItem.find((item: any) => item.type === "Modal");

      // -setModalDetail(modalObj);
      setOpenModal(true);
    }
  }, [showModal.index]);

  const isEndPage = useMemo(() => {
    const currentPage = props.match.params.page
      ? parseInt(props.match.params.page)
      : 1;
    let totalPage = 0;

    if (diagRuleContent[0]) {
      totalPage =
        typeof diagRuleContent[0].total_page === "undefined"
          ? 2
          : diagRuleContent[0].total_page;
    }

    return currentPage === totalPage && totalPage === 2;
  }, [props.match.params.page, diagRuleContent]);

  const handleSetDefaultLang = () => {
    if (navigator && !cookiesFormLang) {
      // -var userLang = navigator.language || navigator.userLanguage;
      let userLang = navigator.languages[0];
      if (userLang.toLowerCase().includes("en")) {
        userLang = LANGUAGE.EN_US;
      } else if (userLang.toLowerCase().includes("th")) {
        userLang = LANGUAGE.TH;
      } else {
        userLang = LANGUAGE.EN_US;
      }
      if (userLang) {
        setUserLang(userLang);
      }
    }
  };

  const handleSetEditAnswer = () => {
    if (Object.keys(editAnswer).length === 0) {
      setAnswer(editAnswer);
      return;
    }

    const firstAnswer = editAnswer[0]?.answer;
    const editAnswerValues = Object.values(editAnswer);
    const obj =
      editAnswerValues.find((item) => item.label === firstAnswer) ||
      editAnswerValues.find((item) => item.selected);

    if (!obj) {
      setAnswer(editAnswer);
      return;
    }

    setFormLanguage(obj.id);
    Cookies.set("formLang", obj.id);

    const filterByType = (item: any) => item.type === "Select";
    const items = (currentForm?.extra_items || []).filter(filterByType);
    const extraItems = obj.extra_items.filter(filterByType);

    const newObj: Record<string, any> = {};
    for (let i = 0; i < extraItems.length; i++) {
      const currentLabel = items?.[i]?.label;
      const label = extraItems[i]?.label || currentLabel;

      if (label && !props.modalOnRefuse) {
        newObj[label] = {
          value: extraItems[i]?.value,
          type: extraItems[i]?.type,
        };
      }
    }
    setAnswer(newObj);
  };

  const getProxyPatientHasDivision = async () => {
    const [response] = await props.controller.getProxyPatientHasDivision({
      apiToken: apiToken || props.apiToken,
    });
    if (isMounted.current) {
      if (response) {
        if (response.items.length > 0 && response.items[0].division) {
          return response.items[0].division;
        }
      }
    }
  };

  const getPublishDiagRuleCheck = async (
    divisionId: number,
    diagRule: number
  ) => {
    const [response] = await props.controller.getPublishDiagRuleCheck({
      apiToken: apiToken || props.apiToken,
      divisionId: divisionId,
      diagRule: diagRule,
      code: "CS",
    });

    if (isMounted.current) {
      if (response) {
        if (response.diag_form) {
          setDiagFormId(response.diag_form);

          return response.diag_form;
        }
      }
    }
  };

  const getPublishDiagRuleDetail = async (params: any) => {
    const [response, error] = await props.controller.getPublishDiagRuleDetail({
      publishDiagRuleId: props.match.params.id,
      apiToken: apiToken || props.apiToken,
    });
    if (isMounted.current) {
      if (response?.content) {
        let json = JSON.parse(response.content);
        setDiagRuleContent(json);

        return response.diag_rule;
      } else {
        setDiagRuleContent({});
        if (error.detail) {
          if (typeof window.iosNative !== "undefined") {
            window.iosNative.showToast(error.detail);
          } else if (typeof window.MobNative !== "undefined") {
            window.MobNative.showToast(error.detail);
          }
        }

        return null;
      }
    }
  };

  const getDiagFormDetail = async (diagFormId: number) => {
    const [response] = await props.controller.getDiagFormDetail({
      diagFormId,
      apiToken: apiToken || props.apiToken,
    });
    if (isMounted.current) {
      if (response?.answers) {
        let ans = JSON.parse(response.answers);

        return setEditAnswer(ans);
      }
    }
  };

  const createFirstPageChoices = () => {
    let choices = Object.values(diagRuleContent).filter(
      (item) => item.parent_id === diagRuleContent[0].id
    );
    console.log("diagRuleContent", diagRuleContent, choices);
    return (
      <div className="content-item">
        <div>{diagRuleContent[0].label}</div>
        <div
          className="answer-options"
          style={{ textAlign: "left", marginTop: "1.5rem" }}
        >
          {choices.map((item, index) => {
            return (
              <div key={`${item.label}_${item.id}`}>
                <div>
                  <Card
                    className={`${classes.card} ${
                      formLanguage == item.id ? " active" : ""
                    }`}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }

                      setFormLanguage(item.id);
                      Cookies.remove("formLang");
                      Cookies.set("formLang", item.id);
                    }}
                  >
                    <CardActionArea>
                      <CardContent>
                        <div className="header">
                          <div>
                            <MuiRadio
                              className="smart-radio-button"
                              name="radio-buttons"
                              checked={formLanguage == item.id}
                            />
                          </div>
                          <div>{item.label}</div>
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  {/* #<Radio
                    label={item.label}
                    name={item.label}
                    value={item.id}
                    checked={formLanguage == item.id}
                    onChange={(e, { checked, value }) => {
                      setFormLanguage(value);
                      Cookies.remove("formLang");
                      Cookies.set("formLang", value);
                    }}
                  />
                  &ensp; */}
                </div>
                {/* <br /> */}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const displaySelect = useMemo(() => {
    const page = props.match.params.page;

    if ((!page || page == 1) && Object.keys(diagRuleContent).length > 1) {
      let choices = Object.values(diagRuleContent).filter(
        (item) => item.parent_id === diagRuleContent[0].id
      );
      return choices
        .map((item, index) => {
          return item.label;
        })
        .filter((item) => Array.isArray(item));
    }

    if (!currentForm?.extra_items) {
      return [];
    }

    return currentForm.extra_items
      .map((item: any, index: number) => {
        if (item.type === "Modal") {
          return null;
        }
        return (
          item.type === "Select" &&
          item.options?.map((optionItem: any, optionIndex: number) => {
            return item.label;
          })
        );
      })
      .filter((item: any) => Array.isArray(item));
  }, [currentForm, props.match, diagRuleContent]);

  const formattedAnswer = useMemo(() => {
    let formatAns = { ...diagRuleContent };
    const consent = currentForm || {};
    const answers: any[] = [];
    const choices: any[] = [];

    if (!formatAns[0] || !formatAns[consent.id]) {
      return { bylabel: answers, bychoice: choices };
    }

    for (let ansItem of Object.keys(answer || {})) {
      const value = answer[ansItem]?.value;

      if (value) {
        answers.push(ansItem);
        choices.push(value);
      }
    }

    return {
      bylabel: answers,
      bychoice: choices,
    };
  }, [diagRuleContent, currentForm, answer]);

  const formatAns = useMemo(() => {
    return formattedAnswer.bylabel;
  }, [formattedAnswer]);

  const allowNextPage = useMemo(() => {
    return displaySelect.every((item: any) => formatAns.includes(item[0]));
  }, [displaySelect, formatAns]);

  const isAllConsent = useMemo(() => {
    return (
      !!allowNextPage &&
      formattedAnswer.bychoice.every((value) =>
        OPTIONS_LABEL.consent.includes(value)
      )
    );
  }, [formattedAnswer, allowNextPage]);

  const handleSelect = ({ index, item, value, options }: any) => {
    console.log("item.label", item.label);

    const ignore =
      !item.showModalIfNotOk && !!item.label?.match(/^<.*?>2.3 /g)?.length;
    const matching =
      item.label?.search(/^<.*?>1\. /g) >= 0 ||
      item.label?.search(/^<.*?>2\.1 /g) >= 0 ||
      item.label?.search(/^<.*?>2\.2 /g) >= 0;

    if (
      (item.showModalIfNotOk || (props.modalOnRefuse && !ignore) || matching) &&
      (value === "ไม่ยินยอม" ||
        value === "Refuse" ||
        value === "ไม่ยอมรับ" ||
        value === "refuse")
    ) {
      setAnswer({
        ...answer,
        [item.label]: {
          value,
          type: item.type,
        },
      });
      setShowModal({
        index,
        label: item.label,
        modal: props.modalOnRefuse,
        telemed: !item.showModalIfNotOk && props.modalOnRefuse,
        choice: /^<.*?>(\d\.\d?)/g.exec(item.label)?.[1] || "",
        options,
      });
    } else {
      setShowModal({});
    }
    setAnswer({
      ...answer,
      [item.label]: {
        value,
        type: item.type,
      },
    });
  };

  const createContent = () => {
    const allOptions = getAllAnswerOptions();

    if (allOptions?.firstPage) {
      return createFirstPageChoices(); // For handle select language
    }

    if (allOptions === null) {
      return;
    }

    return allOptions.map((item: any, index: number) => {
      if (item.type === "Modal") {
        return <React.Fragment key={`${item.label}_${item.type}`} />;
      }

      return (
        <div key={`${item.label}_${item.type}`} className="content-item">
          <div dangerouslySetInnerHTML={{ __html: item.label }} />
          <div
            className="answer-options"
            style={{
              marginTop:
                item.type === "Select" && !!item.options?.length ? "1rem" : "",
            }}
          >
            {item.type === "Select" &&
              item.options?.map((optionItem: any, optionIndex: number) => {
                const checked =
                  !!answer[item.label]?.value &&
                  answer[item.label]?.value === optionItem;

                return (
                  <span key={`${item.label}_${item.type}_${optionItem}`}>
                    <label
                      aria-hidden="true"
                      style={{ display: "inline-block" }}
                      onClick={() => {
                        if (!checked) {
                          handleSelect({
                            index,
                            item,
                            value: optionItem,
                            options: item.options,
                          });
                        }
                      }}
                    >
                      <MuiRadio
                        className="smart-radio-button"
                        name="radio-buttons"
                        checked={checked}
                        style={{ marginTop: "-8px" }}
                      />
                      <label>{optionItem}</label>
                    </label>
                    {/* #<Radio
                      label={optionItem}
                      name={item.label}
                      value={optionItem}
                      checked={
                        !!answer[item.label]?.value &&
                        answer[item.label]?.value === optionItem
                      }
                      onChange={(e, { checked, value }) => {
                        handleSelect({ index, item, value });
                      }}
                    /> */}
                    &ensp;
                  </span>
                );
              })}
          </div>
          <br />
        </div>
      );
    });
  };

  const getAllAnswerOptions = () => {
    if (
      (!props.match.params.page || props.match.params.page == 1) &&
      Object.keys(diagRuleContent).length > 1
    ) {
      return { firstPage: true };
    }

    if (!currentForm?.extra_items) {
      return null;
    }

    return currentForm.extra_items;
    //   .filter(item => {
    //     if (Object.keys(diagRuleContent).length > 1) {
    //       return (
    //         item.page ==
    //         (props.match.params.page
    //           ? parseInt(props.match.params.page) - 1
    //           : 0)
    //       );
    //     } else {
    //       return (
    //         item.page ==
    //         (props.match.params.page ? parseInt(props.match.params.page) : 1)
    //       );
    //     }
    //   })
  };

  const handleNextPage = () => {
    goToNextPage();
  };

  const goToNextPage = () => {
    let nextPage =
      (props.match.params.page ? parseInt(props.match.params.page) : 1) + 1;

    if (nextPage <= 2) {
      props.onHistoryPushState?.({
        pathname: `/consent-hospital-form/${props.match.params.id}/${nextPage}/`,
      });
    } else {
      handleFinishForm();
    }
  };

  const createUpdateDiagForm = async (finished: boolean) => {
    setIsLoading(true);

    let formatAns = JSON.parse(JSON.stringify(diagRuleContent));
    const extraItems = diagRuleContent[currentForm.id].extra_items.filter(
      (item: any) => item.type === "Select"
    );
    formatAns[0].answer = currentForm.label;
    formatAns[currentForm.id].selected = true;
    formatAns[currentForm.id].extra_items = [];
    formatAns[currentForm.id].extra_finished = true;

    for (let item of extraItems) {
      formatAns[currentForm.id].extra_items.push({
        // label: ansItem,
        value: answer[item.label]?.value || "",
        type: item?.type,
      });
    }

    let answer2_2 = "";
    let disableFeature = "";
    if (formatAns[currentForm.id].extra_items[3] != null) {
      answer2_2 = formatAns[currentForm.id].extra_items[3].value;
    }

    let ids = Array.from(new Set([Number(props.match.params.id)]));

    if (OPTIONS_LABEL.refuse.includes(answer2_2)) {
      disableFeature = "phr";
    }

    const bdmsDiagRuleId: number = props.consentsList?.find(
      (item: any) => item.name === "ConsentFormBDMS"
    )?.published_diag_rule;

    if (bdmsDiagRuleId != null) {
      ids = Array.from(
        new Set([Number(props.match.params.id), bdmsDiagRuleId])
      );
    } else if (props.publishedDiagRule) {
      ids = Array.from(
        new Set([Number(props.match.params.id)])
      );
    }

    // -const [res, err] = await props.controller.createDiagForm(
    //   props.apiToken ? props.apiToken : apiToken,
    //   props.patientData.patient, //patientId
    //   Number(props.match.params.id), //diagFormId
    //   JSON.stringify(formatAns), //answer
    //   undefined, // triageLevel
    //   finished,
    //   formatAns[currentForm.id].text // text
    // );
    let promiseArr = [];
    // check ว่ามี diagform หรือไม่ ถ้ามีให้ updateDiagForm
    if (updateConsent !== 0) {
      promiseArr = ids.map((id) =>
      {
        return props.controller.updateDiagForm({
          apiToken: props.apiToken ? props.apiToken : apiToken,
          diagFormId: updateConsent,
          answers: JSON.stringify(formatAns), 
          finished: finished,
          triageLevel: undefined,
          text: formatAns[currentForm.id].text}
        )
      }
      );
    } else {
      promiseArr = ids.map((id) =>
        props.controller.createDiagForm(
          props.apiToken ? props.apiToken : apiToken,
          props.patientData.patient, //patientId
          id, //diagFormId
          JSON.stringify(formatAns), //answer
          undefined, // triageLevel
          finished,
          formatAns[currentForm.id].text // text
        )
      );
    }

    const response = await Promise.all(promiseArr);
    const success = response.every((res) => res[0]?.id);

    if (success) {
      if (typeof window.iosNative !== "undefined") {
        window.iosNative?.disableFeature(disableFeature);
      } else if (typeof window.MobNative !== "undefined") {
        window.MobNative?.disableFeature(disableFeature);
      } else if (typeof window.mobile !== "undefined") {
        window.mobile?.disableFeature?.(disableFeature);
      }

      // -res.id == formId != diagFormId
      // -let params = {
      //   apiToken: props.apiToken ? props.apiToken : apiToken,
      //   diagFormId: res.id,
      //   answers: JSON.stringify(formatAns),
      //   text: formatAns[currentForm.id].text,
      //   finished,
      // };
      // -const [response, error] = await props.controller.updateDiagForm(params);
      setIsLoading(false);
      return response[0][0];
    }

    setIsLoading(false);

    return { error: response[0]?.[1] };
  };

  const handleFinishForm = async ({ finished } = { finished: true }) => {
    const response = await createUpdateDiagForm(true);

    if (response?.error) {
      return setModError(response?.error);
    }

    if (props.selectHospital) {
      return (
        window.mobile.selectHospitalCode(props.selectHospitalCode),
        window.mobile.selectHospitalArray([
          props.selectHospitalCode,
          props.selectHospitalName,
        ])
      );
    } else {
      if (
        history.location.state &&
        !props.hideAgreeButton &&
        props.publishedDiagRule
      ) {
        // await props.controller.createDiagForm(
        //   props.apiToken ? props.apiToken : apiToken,
        //   props.patientData.patient, //patientId
        //   props.publishedDiagRule, //DiagFormId
        //   response.answers,
        //   undefined,
        //   true
        // );

        if (globalThis.mobile?.getNewHN && props.publishedDiagRule) {
          // iOS Android
          globalThis.mobile.getNewHN?.();
        }
      }

      if (
        props.isTelemed &&
        !props.hideAgreeButton &&
        !props.isTelemedFinished
      ) {
        return props.onGoToTelemedPage?.({
          answer,
          diagRuleContent,
          formLanguage,
          currentForm,
          diagFormId,
          editAnswer,
          userLang,
          formLangText,
          isBottom,
          pathname: history.location.pathname,
        });
      } else if (props.onFormFinished) {
        props.onFormFinished();
      } else if (history.location.state) {
        handleGotoAppointmentComplete();

        return;
      }
      //  else if (typeof window.iosNative !== "undefined") {
      //   return window.iosNative.dismissWebView();
      // } else if (typeof window.MobNative !== "undefined") {
      //   console.log("saika ~ window.MobNative:")
      //   return window.MobNative.dismissWebView();
      // }
      else if (props.publishedDiagRule) {
        console.log("saika ~ setOpenSuccessModal:");
        setOpenSuccessModal(true);
      } else {
        return history.push("/consent-hospital-conclusion/");
      }
    }
  };

  const handleGotoAppointmentComplete = () => {
    const state = history.location.state;
    const locState = JSON.parse(state);
    const index = (locState.routes || [])
      .reverse()
      .findIndex((route: string) => route === "/confirm-appointment");

    if (locState.actionType === "PAYMENT") {
      history.go(-(index + 1));

      setTimeout(() => {
        history.replace({
          pathname: `/confirm-appointment`,
          search: `?app=MobSmartAppointment&organizationName=${props.selectHospitalName || locState?.locationState?.hospital?.name || ""}`,
          state,
        });

        globalThis.history.go();
      }, 10);

    } else if (index !== -1) {
      history.go(-(index + 1));

      setTimeout(() => {
        history.replace({
          pathname: `/confirm-appointment`,
          search: `?app=MobSmartAppointment&organizationName=${props.selectHospitalName || locState?.locationState?.hospital?.name || ""}`,
          state,
        });

        globalThis.history.go();
      }, 10);
    } else {
      if (config.ENABLE_SMARTAPPOINMENT) {
        setTimeout(() => {
          history.replace({
            pathname: `/confirm-appointment`,
            search: `?app=MobSmartAppointment&organizationName=${props.selectHospitalName || locState?.locationState?.hospital?.name || ""}`,
            state,
          });

          globalThis.history.go();
        }, 10);

      } else {
        history.push({
          pathname: "/makeappointmentUnsign",
          state,
          search: "?app=MobAppointment",
        });
  
        globalThis.history.go();
      }
    }
  };

  // -const handleModalButtonClick = async (e: any) => {
  //   const { textContent } = e.target;
  //   if (textContent === "ไม่ใช่" || textContent === "No") {
  //     const cloneAnswer = { ...answer };
  //     delete cloneAnswer[showModal?.label || ""];

  //     setShowModal({});
  //     setOpenModal(false);
  //     setAnswer({ ...cloneAnswer });
  //   } else if (textContent === "ใช่" || textContent === "Yes") {
  //     if (showModal.telemed && props.isTelemed && !props.hideAgreeButton) {
  //       history.push("/select-specialty/?app=MobAppointment");
  //       return globalThis.history.go();
  //     } else {
  //       handleCheckAnswer();
  //       await createUpdateDiagForm(true);
  //       setOpenModal(false);
  //       props.onCheckType?.("diagUnConfirm");
  //     }
  //   }
  // };

  // -const handleCheckAnswer = () => {
  //   // ถ้าผู้ป่วยไม่ยินยอมในข้อในข้อที่ 2.2 จะถือว่า ไม่ยินยอม ทุกข้อที่ยังไม่ได้ได้ตอบ
  //   if (!allowNextPage) {
  //     const allOptions = getAllAnswerOptions();
  //     const cloneAnswer = { ...answer };
  //     if (Array.isArray(allOptions)) {
  //       // @ts-ignore
  //       for (const [i, item] of allOptions.entries()) {
  //         if (item.type === "Select" && item.options?.length) {
  //           for (const optionItem of item.options) {
  //             if (OPTIONS_LABEL.refuse.includes(optionItem.toLowerCase())) {
  //               if (cloneAnswer[item.label].value === undefined) {
  //                 cloneAnswer[item.label] = {
  //                   value: optionItem,
  //                   type: item.type,
  //                 };
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //     // -isAgreeAllRef.current = true;
  //     setAnswer(cloneAnswer);
  //   }
  // };

  const handleAgreeAll = async () => {
    const allOptions = getAllAnswerOptions();
    const cloneAnswer = { ...answer };

    if (Array.isArray(allOptions)) {
      const targetOptionsLabel =
        OPTIONS_LABEL[isAllConsent ? "refuse" : "consent"];

      for (const item of allOptions) {
        if (
          item.type === "Select" &&
          item.options?.some((optionItem: any) =>
            targetOptionsLabel.includes(optionItem.toLowerCase())
          )
        ) {
          const valueToSet = isAllConsent
            ? ""
            : item.options.find((optionItem: any) =>
                targetOptionsLabel.includes(optionItem.toLowerCase())
              );

          cloneAnswer[item.label] = {
            value: valueToSet,
            type: item.type,
          };
        }
      }
    }

    // -isAgreeAllRef.current = true;
    setAnswer(cloneAnswer);
  };

  const handleApprove = () => {
    const cloneAnswer = { ...answer };
    cloneAnswer[showModal?.label || ""] = {
      type: "Select",
      value: showModal.options?.[0] || "",
    };

    handleDeny();
    setAnswer({ ...cloneAnswer });
  };

  const handleDeny = () => {
    setShowModal({});
    setOpenModal(false);
  };

  const handleGotoFeed = () => {
    if (window.mobile?.goToMainScreen) {
      window.mobile?.goToMainScreen();
    } else if (window.MobNative?.goToMainScreen) {
      window.MobNative?.goToMainScreen();
    } else if (window.iosNative?.goToMainScreen) {
      window.iosNative?.goToMainScreen();
    } else {
      globalThis.window.location.href = "/?app=MobFeed";
    }
  };

  const handleCloseModError = () => {
    setModError(null);
  };

  console.log("props", props);

  return (
    <div ref={consentFormRef} className="consent-form  --hospital">
      {/* -<ModInfo
        open={openSuccessModal}
        type="success"
        duration={500}
        onClose={() => {
          setOpenSuccessModal(false);
          if (typeof window.iosNative !== "undefined") {
            window.iosNative.diagConfirm();
          } else if (typeof window.MobNative !== "undefined") {
            window.MobNative.diagConfirm();
          } else {
            history.push("/");
          }
        }}
      /> */}
      {/* -<Modal open={openModal} className="mod-consent-form">
        <div dangerouslySetInnerHTML={{ __html: modalDetail?.label }} />
        <div></div>
        <br />
        <div className="btn-group">
          {modalDetail?.options &&
            (modalDetail?.options).map((optionItem: any, index: number) => {
              return (
                <Button
                  key={index}
                  content={optionItem}
                  fluid
                  onClick={handleModalButtonClick}
                  loading={isLoading}
                />
              );
            })}
        </div>
      </Modal> */}
      {props.menuBar}
      <Dimmer.Dimmable>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        <div className={isEndPage ? "content-no-padding" : "content"}>
          {useMemo(
            () => createContent(),
            [
              props.match.params.page,
              diagRuleContent,
              currentForm,
              formLanguage,
              answer,
              isLoading,
            ]
          )}
        </div>
        {isEndPage ? (
          // isBottom && (
          <div className="contentSegment-no-fixed ">
            <FooterButtonConfirm
              formLangText={formLangText}
              isAllConsent={isAllConsent}
              // config
              allowNextPage={allowNextPage}
              hideAgreeButton={props.hideAgreeButton}
              // callback
              onAgreeAll={handleAgreeAll}
              onConfirm={handleNextPage}
            />
          </div>
        ) : (
          // )
          <FooterButton
            // data
            page={props.match.params.page}
            formLangText={formLangText}
            isLoading={isLoading}
            diagRuleContent={diagRuleContent}
            // config
            allowNextPage={allowNextPage}
            // callback
            onNextPage={handleNextPage}
          />
        )}
      </Dimmer.Dimmable>

      {openSuccessModal && (
        <ModConsentSuccess
          organizationName={props.organizationName || ""}
          onApprove={handleGotoFeed}
          onDeny={() => setOpenSuccessModal(false)}
        />
      )}

      <DialogConfirm
        open={openModal}
        title={"การยินยอมให้เปิดเผยข้อมูล"}
        description={
          <div style={{ whiteSpace: "pre-line", marginTop: "0.5rem" }}>
            {["1.", "2.1"].includes(showModal.choice || "")
              ? "หากโรงพยาบาลไม่ได้รับความยินยอมจาก\nท่านในข้อดังกล่าว ท่านจะไม่สามารถ \nนัดหมายแพทย์ ได้"
              : "หากโรงพยาบาลไม่ได้รับความยินยอมจาก\nท่านในข้อดังกล่าว ท่านจะไม่สามารถ\nนัดหมายแพทย์ หรือดู PHR ได้"}
          </div>
        }
        img={IMAGES.consent}
        imgSize="150px"
        denyBasic={true}
        approveText={"ยินยอม"}
        denyText={"ไม่ยินยอม"}
        // callback
        onApprove={handleApprove}
        onDeny={handleDeny}
      />

      <DialogConfirm
        open={!!modError}
        title={"ขออภัยค่ะ"}
        approveText="ตกลง"
        img={IMAGES.cancel_hn}
        imgStyle={{ margin: "-1rem 0px -0.5rem" }}
        // config
        hideDeny={true}
        onApprove={handleCloseModError}
      >
        <ErrorMessage
          error={{
            detail: "Invalid token.",
          }}
          style={{ marginTop: "0.5rem", width: "100%" }}
        />
      </DialogConfirm>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                  FooterButtonConfirm;                  */

/* ------------------------------------------------------ */
const FooterButtonConfirm = (props: any) => {
  const classes = useStyles();

  return (
    <Segment
      style={{
        padding: "15px 10px",
        border: 0,
        backgroundColor: "white",
      }}
    >
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 0,
        }}
      > */}
      {/* #{!props.hideAgreeButton && (
          <MuiButton
            className={classes.button}
            variant="contained"
            onClick={props.onAgreeAll}
          >
            {props.formLangText === "en-US" ? "Agree all" : "ยินยอมทั้งหมด"}
          </MuiButton>
        )} */}
      {/* <MuiButton
          className={classes.button}
          variant="contained"
          onClick={props.onConfirm}
          disabled={!props.allowNextPage}
        >
          {props.formLangText === "en-US" ? "Confirm" : "ยืนยัน"}
        </MuiButton> */}
      {/* </div> */}
      {!props.hideAgreeButton && (
        <FormGroup className={classes.checkbox_primary}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={props.isAllConsent}
                onClick={props.onAgreeAll}
              />
            }
            label="ยินยอมทั้งหมด"
          />
        </FormGroup>
      )}
      <div
        className="button-submit-bottom"
        style={{
          padding: 0,
          marginTop: props.hideAgreeButton ? "9px" : "-0.5rem",
        }}
      >
        <MuiButton
          variant="contained"
          color="primary"
          style={{ width: "100%" }}
          disabled={!props.allowNextPage}
          onClick={props.onConfirm}
        >
          ยืนยัน
        </MuiButton>
      </div>
    </Segment>
  );
};

/* ------------------------------------------------------ */

/*                      FooterButton;                     */

/* ------------------------------------------------------ */
const FooterButton = (props: any) => {
  return (
    <Segment style={{ backgroundColor: "transparent" }}>
      {/* <Button
        fluid
        content={
          props.page === "2"
            ? props.formLangText === "en-US"
              ? "Done"
              : "เสร็จสิ้น"
            : props.formLangText === "en-US"
            ? "Next"
            : "ต่อไป"
        }
        onClick={props.onNextPage}
        loading={props.isLoading}
        disabled={props.isLoading || !props.allowNextPage}
      /> */}
      <div className="button-submit-bottom" style={{ marginBottom: "3rem" }}>
        <MuiButton
          variant="contained"
          color="primary"
          disabled={!!props.loading}
          onClick={props.onNextPage}
        >
          ถัดไป
          <Loader
            size="small"
            active={!!props.loading}
            inline={true}
            style={{ marginLeft: "0.5rem" }}
          ></Loader>
        </MuiButton>
      </div>
      {/* <div>
        <span>{`${props.page ? parseInt(props.page) : 1}/${
          props.diagRuleContent[0] && props.diagRuleContent[0].total_page
            ? props.diagRuleContent[0].total_page
            : 2
        }`}</span>
      </div> */}
    </Segment>
  );
};

HospitalConsentContent.defaultProps = {
  onFormLanguageChange: () => {},
};

export default memo(HospitalConsentContent);
