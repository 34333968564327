import { Component, createRef } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
// CSS

// Library
import Cookies from "js-cookie";
import Fingerprint2 from "fingerprintjs2";
import { IntlProvider } from "react-intl";
import { vcMessenger } from "../react-lib/compat/vc-websocket";

// Frameworks
import SetPropSeparate from "react-lib/frameworks/SetProp";

// IsHealth
import { createNotificationSubscription } from "../react-lib/apps/IsHealth/Common/push-notifications";

// localization
import thMessage from "react-lib/localization/bplusClinic/th.json";
import enMessage from "react-lib/localization/bplusClinic/en.json";

// UX
import DoctorAppointment from "./smartappointment/DoctorAppointment";
import SearchDoctor from "./smartappointment/SearchDoctor";
import SelectCenter from "./smartappointment/SelectCenter";
import DoctorProfile from "./smartappointment/DoctorProfile";
import SelectClinic from "./smartappointment/SelectClinic";
import MakeAppointmentFor from "./smartappointment/MakeAppointmentFor";
import AppointmentReason from "./smartappointment/AppointmentReason";
import SelectDatetime from "./smartappointment/SelectDatetime";
import HealthProblem from "./smartappointment/HealthProblem";
import ConfirmAppointment from "./smartappointment/ConfirmAppointment";
import MainAppointment from "./smartappointment/MainAppointment";
import CancelAppointment from "./smartappointment/CancelAppointment";
import SubmitRequestRefund from "./smartappointment/SubmitRequestRefund";
import SelectCheckupHospital from "./smartappointment/SelectCheckupHospital";
import SelectCheckupType from "./smartappointment/SelectCheckupType";

// Controller
import MobSmartAppointmentController from "./MobSmartAppointmentController";

// Interface
import * as MobSmartAppointmentI from "./MobSmartAppointmentInterface";

// CSS
import "../css/MobAppointment.scss";
import "../css/WebClinic.scss";

// Config
import CONFIG from "../config/config";

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  PENGUIN: "penguin",
};

class MobPayment extends Component<any, MobSmartAppointmentI.State> {
  controller: MobSmartAppointmentController;

  currentPathname: any;
  unlisten: any;

  constructor(props: any) {
    super(props);

    this.state = { ...MobSmartAppointmentI.StateInitial };

    this.controller = new MobSmartAppointmentController(
      () => {
        return this.state;
      },
      (state: MobSmartAppointmentI.State, callback: any) => {
        this.setState(state, callback);
      },
      window
    );

    // @ts-ignore
    globalThis.setState = this.setState.bind(this);

    this.controller.setProp = SetPropSeparate(this, "");
    this.controller.cookies = Cookies;

    this.currentPathname = createRef();
    this.unlisten = createRef();
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} });

    // Get login info from Mobile App
    if (!this.state.loggedin) {
      this.controller.handleEvent({
        message: "GetLoginInfo",
        params: {},
      });
    }

    // -const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

    // -if (prefersDarkScheme.matches) {
    //   document.documentElement.setAttribute("color-scheme", "light");
    // } else {
    //   document.documentElement.setAttribute("color-scheme", "light");
    // }
    document.documentElement.setAttribute("color-scheme", "light");

    if (CONFIG.COMPANY === "BDMS") {
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
    } else {
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
    }

    document.querySelector("body")?.classList.add("body-mob-appointment");

    const userProfile = window.mobile?.getUser?.() || "{}";
    const user = JSON.parse(userProfile || "{}");

    if (user?.device_type === "android") {
      requestIdleCallback(() => {
        console.log("get fingerprint on requestIdleCallback");
        this.getFingerPrint();
        this.getApiToken(this.props);
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(() => {
        this.getFingerPrint();
        this.getApiToken(this.props);
      }, 500);
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.apiToken !== this.state.apiToken &&
      prevState.userId !== this.state.userId &&
      this.state.userId &&
      this.state.apiToken
    ) {
      console.log("did update check......");
      Cookies.set("apiToken", this.state.apiToken || "", { path: "/" });
      Cookies.set("userId", this.state.userId.toString() || "", { path: "/" });
      this.controller.handleEvent({
        message: "GetMyProfileDetail",
        params: {},
      });
    }
  }

  componentWillMount() {
    this.currentPathname.current = this.props.history.location.pathname;
    this.unlisten = this.props.history.listen(
      (location: any, action: string) => {
        this.controller.handleEvent({
          message: "HandleRouteChange",
          params: {
            currentPathname: this.currentPathname.current,
            nextPathname: location.pathname,
            action,
          },
        });
        this.currentPathname.current = location.pathname;
      }
    );
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getFingerPrint = () => {
    let options: Record<string, any> = {};

    Fingerprint2.getPromise(options).then((components) => {
      // components is array of {key: 'foo', value: 'component value'}
      let values = components.map(function (component) {
        return component.value;
      });
      let device_id = Fingerprint2.x64hash128(values.join(""), 31);

      this.setState({ device_id: device_id });

      console.log("MP Fingerprint id:", device_id);
      // Get subscription endpoint object
      createNotificationSubscription()
        .then((subscription) => {
          this.setState({ subscription: subscription });

          console.log("MP Success get notification subscription..");
          console.log(subscription);
        })
        .catch((err) => {
          console.log("MP error getSubScriptionObejct");
          console.error(
            "Couldn't create the notification subscription",
            err,
            "name:",
            err.name,
            "message:",
            err.message,
            "code:",
            err.code
          );
        });
    });
  };

  getApiToken = async (props: any) => {
    console.log(" getApiToken called");
    let apiToken;
    let userId;

    if (window.mobile?.getUser) {
      let userProfile = window.mobile.getUser();
      const user = JSON.parse(userProfile || "{}");
      console.log("get apiToken from ", user?.device_type, user);

      apiToken = user?.token || "";
      userId = user?.profile?.userId || "";
      Cookies.set("apiToken", apiToken, { path: "/" });
      Cookies.set("userId", userId, { path: "/" });
      // this.getPatientDetail({ token: user.token })
      this.setWebsocket({ apiToken: apiToken });
      this.setState({ apiToken: apiToken, userId: userId });
    } else {
      console.log("get apiToken from browser (cookies)");

      if (props) {
        apiToken = Cookies.get("apiToken");
        userId = Cookies.get("userId");

        this.setState({ apiToken: apiToken, userId: userId });

        console.log(" apiToken", apiToken);
        console.log(" userId", userId);
      }
    }
  };

  setWebsocket = ({ apiToken }: any = {}) => {
    console.log("setWebsocket");
    console.log(apiToken, "apiToken");
    console.log(CONFIG.WS_HOST, "CONFIG.WS_HOST");

    vcMessenger.connect(
      "MSG",
      {
        token: apiToken,
      },
      CONFIG.WS_HOST
    );
  };

  render = () => {
    console.log("MobSmartAppointment this.state: ", this.state);
    return (
      <IntlProvider
        locale={this.state.language?.includes("TH") ? "th" : "en"}
        // messages={flattenMessages(messages["en-US"])}
        messages={this.state.language?.includes("TH") ? thMessage : enMessage}
      >
        <div style={{ height: "100vh" }}>
          <Switch>
            <Route
              path="/select-centergroup/:medProgram?"
              render={(props) => {
                return (
                  <DoctorAppointment
                    onEvent={this.controller.handleEvent}
                    setProp={this.controller.setProp}
                    // data
                    language={this.state.language}
                    hospitalList={this.state.hospitalList}
                    topServiceList={this.state.topServiceList}
                    loadingStatus={this.state.loadingStatus}
                    centerGroupList={this.state.centerGroupList}
                    selectedHospital={this.state.selectedHospital}
                    // config
                    simulator={this.props.simulator}
                  />
                );
              }}
            ></Route>
            <Route
              path="/search-doctor"
              render={(props) => {
                return (
                  <SearchDoctor
                    onEvent={this.controller.handleEvent}
                    setProp={this.controller.setProp}
                    // data
                    textSearch={this.state.textSearch}
                    loadingSearch={this.state.loadingSearch}
                    doctorSearchList={this.state.doctorSearchList}
                    hospitalList={this.state.hospitalList}
                    availableDoctorSearch={this.state.availableDoctorSearch}
                    allAvailableDoctorList={this.state.allAvailableDoctorList}
                    centerGroupList={this.state.centerGroupList}
                    appointmentCenterList={this.state.appointmentCenterList}
                    // CommonInterface
                    loadingStatus={this.state.loadingStatus}
                  />
                );
              }}
            ></Route>
            <Route
              path="/select-center"
              render={(props) => {
                return (
                  <SelectCenter
                    onEvent={this.controller.handleEvent}
                    setProp={this.controller.setProp}
                    // data
                    hospitalList={this.state.hospitalList}
                    centerGroupList={this.state.centerGroupList}
                    appointmentCenterList={this.state.appointmentCenterList}
                  />
                );
              }}
            ></Route>
            <Route
              path="/select-clinic"
              render={(props) => {
                return <SelectClinic onEvent={this.controller.handleEvent} />;
              }}
            ></Route>
            <Route
              path="/doctor-profile"
              render={(props) => {
                return (
                  <DoctorProfile
                    onEvent={this.controller.handleEvent}
                    // data
                    doctorProfile={this.state.doctorProfile}
                    language={this.state.language}
                    // CommonInterface
                    loadingSkeleton={this.state.loadingSkeleton}
                    loadingStatus={this.state.loadingStatus}
                  />
                );
              }}
            ></Route>
            <Route
              path="/appointment-for"
              render={(props) => {
                return (
                  <MakeAppointmentFor onEvent={this.controller.handleEvent} />
                );
              }}
            ></Route>
            <Route
              path="/appointment-reason"
              render={(props) => {
                return (
                  <AppointmentReason
                    onEvent={this.controller.handleEvent}
                    // data
                    medServiceLists={this.state.medServiceLists}
                    // CommonInterface
                    loadingStatus={this.state.loadingStatus}
                  />
                );
              }}
            ></Route>
            <Route
              path="/select-datetime"
              render={(props) => {
                return (
                  <SelectDatetime
                    onEvent={this.controller.handleEvent}
                    setProp={this.controller.setProp}
                    // data
                    language={this.state.language}
                    calendarList={this.state.calendarList}
                    timeslotList={this.state.timeslotList}
                    isLoadingCalendar={this.state.isLoadingCalendar}
                    isLoadingNextMonth={this.state.isLoadingNextMonth}
                    loadingSkeleton={this.state.loadingSkeleton}
                    appointmentSlotList={this.state.appointmentSlotList}
                    menuTabAppointmentSlot={this.state.menuTabAppointmentSlot}
                    // CommonInterface
                    loadingStatus={this.state.loadingStatus}
                    successMessage={this.state.successMessage}
                    errorMessage={this.state.errorMessage}
                  />
                );
              }}
            ></Route>
            <Route
              path="/health-problem"
              render={(props) => {
                return (
                  <HealthProblem
                    onEvent={this.controller.handleEvent}
                    // data
                    language={this.state.language}
                    calendarList={this.state.calendarList}
                    timeslotList={this.state.timeslotList}
                    isLoadingCalendar={this.state.isLoadingCalendar}
                    isLoadingNextMonth={this.state.isLoadingNextMonth}
                    loadingSkeleton={this.state.loadingSkeleton}
                    appointmentSlotList={this.state.appointmentSlotList}
                    menuTabAppointmentSlot={this.state.menuTabAppointmentSlot}
                  />
                );
              }}
            ></Route>
            <Route
              path="/confirm-appointment"
              render={(props) => {
                return (
                  <ConfirmAppointment
                    onEvent={this.controller.handleEvent}
                    setProp={this.controller.setProp}
                    // data
                    language={this.state.language}
                    myProfileDetail={this.state.myProfileDetail}
                    appointmentParams={this.state.appointmentParams}
                    medServiceLists={this.state.medServiceLists}
                    // CommonInterface
                    loadingStatus={this.state.loadingStatus}
                    successMessage={this.state.successMessage}
                    errorMessage={this.state.errorMessage}
                  />
                );
              }}
            ></Route>
            <Route
              path="/cancel-appointment"
              render={(props) => {
                return (
                  <CancelAppointment
                    onEvent={this.controller.handleEvent}
                    setProp={this.controller.setProp}
                    // data
                    myProfileDetail={this.state.myProfileDetail}
                    // CommonInterface
                    loadingStatus={this.state.loadingStatus}
                    successMessage={this.state.successMessage}
                    errorMessage={this.state.errorMessage}
                  />
                );
              }}
            ></Route>
            <Route
              path="/submit-request"
              render={(props) => {
                return <SubmitRequestRefund
                onEvent={this.controller.handleEvent}
                setProp={this.controller.setProp}
                // data
                allPaidInvoiceList={this.state.allPaidInvoiceList}
                myProfileDetail={this.state.myProfileDetail}
                // CommonInterface
                loadingStatus={this.state.loadingStatus}
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
                />;
              }}
            ></Route>
            <Route
              path="/select-checkup-hospital"
              render={(props) => {
                return (
                  <SelectCheckupHospital
                    onEvent={this.controller.handleEvent}
                    // data
                    hospitalList={this.state.hospitalList}
                  />
                );
              }}
            ></Route>
            <Route
              path="/select-checkup-type"
              render={(props) => {
                return (
                  <SelectCheckupType
                    onEvent={this.controller.handleEvent}
                    medProgramLists={this.state.medProgramLists}
                    // CommonInterface
                    loadingStatus={this.state.loadingStatus}
                  />
                );
              }}
            ></Route>
            <Route
              path="/"
              render={(props) => {
                return (
                  <>
                    {props.location.search.includes("MobSmartAppointment") && (
                      <MainAppointment
                        onEvent={this.controller.handleEvent}
                        // data
                        language={this.state.language}
                        myProfileDetail={this.state.myProfileDetail}
                        hospitalList={this.state.hospitalList}
                        selectedHospital={this.state.selectedHospital}
                        requestedAppointmentList={
                          this.state.requestedAppointmentList
                        }
                        refundRequestsList={this.state.refundRequestsList}
                        allPaidInvoiceList={this.state.allPaidInvoiceList}
                        myAppointmentList={this.state.myAppointmentList}
                        cancelAppointmentList={this.state.cancelAppointmentList}
                        // CommonInterface
                        loadingStatus={this.state.loadingStatus}
                      />
                    )}
                  </>
                );
              }}
            ></Route>
          </Switch>
        </div>
      </IntlProvider>
    );
  };
}

export default withRouter(MobPayment);
