import WasmController from "react-lib/frameworks/WasmController";

import { TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";
import { PDFDocument, PDFPage } from "pdf-lib";

import * as SearchBoxI from "react-lib/appcon/common/SearchBoxI";
import getPdfMake from "react-lib/appcon/common/pdfMake";

import MasterMap from "./MasterMap";

import * as AppInitialize from "./sequence/AppInitialize";
import * as RejectOrderI from "./sequence/RejectOrder";
import * as ZoneLogI from "./sequence/ZoneLog";
import { Event as MainEvent, State as MainState } from "HIS/MainHISInterface";

import { getLogoFormPath } from "react-lib/utils/imageUtils";

import CONFIG from "config/config";

export type State = {
  masterOptions?: any;
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}
} & AppInitialize.State &
  ZoneLogI.State &
  SearchBoxI.State &
  RejectOrderI.State;

export const StateInitial: State = {
  masterOptions: {},
  errorMessage: {},
  successMessage: {},
  buttonLoadCheck: {}, // {cardName: LOADING || SUCCESS || ERROR}
  ...AppInitialize.StateInitial,
  // ...ZoneLogI.StateInitial,
  // ...SearchBoxI.StateInitial,
  // ...RejectOrderI.StateInitial,
};

export type Event =
  | { message: "GetMasterData"; params: { by: string } }
  | { message: "Logout"; params: {} }
  | AppInitialize.Event
  | ZoneLogI.Event
  | SearchBoxI.Event
  | RejectOrderI.Event;

export type Data = {
  division?: number;
  device?: number;
  masterData?: { [name: string]: any };
} & AppInitialize.Data &
  ZoneLogI.Data &
  SearchBoxI.Data &
  RejectOrderI.Data;

export type PickMainState<T extends keyof MainState> = Pick<MainState, T>;

export type ActionDefinition<A extends string, P, S extends string> = {
  [K in A]: {
    sequence: S;
    action: K;
  } & (K extends keyof P ? P[K] : unknown);
}[A];

export type ConditionalExtract<T, U> = T extends T ? (U extends Partial<T> ? T : never) : never;

type PrefixedActions<T extends Record<string, string>, P extends string> = {
  [K in keyof T]: `${P}_${T[K]}`;
};

type OptionType = {
  key: number | string;
  original?: Record<string, any>;
  text: string;
  value: number | string;
};

export type MasterMapOptions<T extends readonly (readonly [string, ...any])[]> = Record<
  T[number][0],
  OptionType[] | undefined
>;

export type BaseSeqState<S extends string> = {
  sequence: S;
  clear?: boolean;
  restart?: boolean;
};

type SeqAct<A, SST> = A & SST;

type SeqType<K, A, SST> = K extends { action: string } ? Extract<SeqAct<A, SST>, K> : SST;

type FlexibleSeqState<S extends string> = BaseSeqState<S> & Record<string, any>;

export type GenerateActionTypes<
  A extends Record<string, string>,
  P extends Partial<Record<A[keyof A], Record<string, any>>>,
  S extends string,
  ST,
  SST extends FlexibleSeqState<S> = BaseSeqState<S>
> = {
  RunSequence: <K extends keyof SeqAct<ActionDefinition<A[keyof A], P, S>, SST>>(
    params: SeqType<
      Pick<SeqAct<ActionDefinition<A[keyof A], P, S>, SST>, K>,
      ActionDefinition<A[keyof A], P, S>,
      SST
    >
  ) => void;
  ActionType: ActionDefinition<A[keyof A], P, S>;
  OnEvent: (e: MainEvent) => unknown;
  Params: {
    [K in A[keyof A]]: ConditionalExtract<ActionDefinition<A[keyof A], P, S>, { action: K }>;
  };
  SetProp: SetProperty<ST>;
};

export type ActionHandler<ST, EV, DT, P = unknown, R = void> = (
  controller: WasmController<ST, EV, DT>,
  ...params: unknown extends P ? [params?: P] : [params: P]
) => R;

export type PickFromPartialNullable<T, K extends keyof NonNullable<T>> =
  | Pick<Required<NonNullable<T>>, K>
  | undefined;

type LiteralOrString<T extends string> = Omit<string, T> | T;

type FormInputProps = {
  checked?: boolean;
  name?: string;
  type?: LiteralOrString<"checkbox" | "radio">;
  value?: any;
};

type InputChangeData<T, S> = T extends { name: ProcessedLeaves<S> }
  ? T extends { value: unknown }
    ? T["value"] extends RetTypeAtPath<T["name"], S>
      ? T
      : { name: T["name"]; value: RetTypeAtPath<T["name"], S> }
    : FormInputProps & { name: T["name"]; value: RetTypeAtPath<T["name"], S> }
  : T extends { name: string }
  ? FormInputProps & { name: ProcessedLeaves<S> }
  : T extends { name?: string }
  ? FormInputProps & T
  : FormInputProps & { name?: ProcessedLeaves<S>; value: unknown };

export type FormInputChangeHandler<T, S> = {
  data: InputChangeData<T, S>;
  name?: ProcessedLeaves<S>;
  value: RetTypeAtPath<ProcessedLeaves<S>, S>;
};

// NonNullableValues: Recursive utility to filter out nullable and undefined types
export type NonNullableValues<T> = {
  [K in keyof T]-?: NonNullable<Required<T>[K]> extends moment.Moment
    ? NonNullable<Required<T>[K]>
    : NonNullable<Required<T>[K]> extends object
    ? NonNullableValues<NonNullable<Required<T>[K]>>
    : NonNullable<Required<T>[K]>;
};

// SplitPathArray: Split a string into an array based on a separator
export type SplitPathString<
  T extends string,
  Sep extends string = ".",
  Acc extends string[] = []
> = T extends `${infer First}${Sep}${infer Rest}`
  ?
      | SplitPathString<First, Sep, Acc>
      | SplitPathString<Rest, Sep, [...Acc, First]>
  : [...Acc, T];

// JoinPathArray: Join an array of strings or numbers into a string with a separator
export type JoinPathArray<
  T extends (number | string)[],
  Sep extends string = "."
> = T extends []
  ? ""
  : T extends [infer First, ...infer Rest]
  ? First extends number | string
    ? `${First}${Rest extends (number | string)[]
        ? Rest extends []
          ? ""
          : `${Sep}${JoinPathArray<Rest, Sep>}`
        : never}`
    : never
  : never;

// SeparatedString: Splits string into segments using `Sep` and constructs a new string `Acc`.
export type SeparatedString<
  T extends string,
  Sep extends string = ".",
  Acc extends string = ""
> = T extends `${infer First}${Sep}${infer Rest}`
  ?
      | SeparatedString<
          Rest,
          Sep,
          Acc extends "" ? `${First}` : `${Acc}${Sep}${First}`
        >
      | SeparatedString<First, Sep, Acc>
  : Acc extends ""
  ? `${T}`
  : `${Acc}${Sep}${T}`;

// RetValueType: Return the type of an object property based on a given path
export type RetValueType<T extends string[], U> = T extends [infer Key, ...infer Rest]
  ? Rest extends string[]
    ? Key extends `${number}`
      ? U extends (infer E)[]
        ? RetValueType<Rest, E>
        : never
      : Key extends keyof U
      ? RetValueType<Rest, U[Key]>
      : Key extends keyof NonNullable<U>
      ? RetValueType<Rest, NonNullable<U>[Key]>
      : never
    : never
  : U;

// PathSegmentArray: Convert a string path into an array of strings or numbers
export type PathSegmentArray<T extends string> =
  T extends `${infer First}.${infer Rest}`
    ? First extends `${number}`
      ? [`${number}`, ...PathSegmentArray<Rest>]
      : [First, ...PathSegmentArray<Rest>]
    : T extends `${infer Last}`
    ? Last extends `${number}`
      ? [`${number}`]
      : [Last]
    : [];

// NestedKeyLookup: nested key lookup in data structures
type NestedKeyLookup<
  T extends string,
  U
> = T extends `${infer Key}.${infer Rest}`
  ? Rest extends `${number}`
    ? Key extends keyof NonNullable<U>
      ? NonNullable<NonNullable<U>[Key]> extends (infer E)[]
        ? NestedKeyLookup<Rest, E>
        : never
      : // else
        never
    : Key extends keyof NonNullable<U>
    ? NestedKeyLookup<Rest, NonNullable<U>[Key]>
    : Key extends `${number}`
    ? NonNullable<U> extends (infer E)[]
      ? NestedKeyLookup<Rest, E>
      : never
    : never
  : T extends `${infer Key}`
  ? Key extends keyof NonNullable<U>
    ? NonNullable<U>[Key]
    : U
  : U;

// SplitPath: Split a string path into an array using a separator
export type SplitPath<T, Sep extends string> = SplitPathString<T & string, Sep>;

// ExtractPathComponent: Join an array of strings or numbers into a string with a separator
export type ExtractPathComponent<T, Sep extends string = "."> = JoinPathArray<
  SplitPath<T, Sep>,
  Sep
>;

// ExcludeMatching: Exclude values matching a given type from another type
type ExcludeMatching<T, V, Temp = T> = T extends T
  ? V extends T
    ? Exclude<Temp, T>
    : never
  : never;

// ------------------------------------------------------

// Leaves: Extract paths of leaf nodes in a nested object
type Leaves<T> = T extends moment.Moment
  ? string
  : T extends object
  ? T extends (infer U)[]
    ? // ? U extends number | string | boolean | null
      //   ? `${number}`
      U extends object
      ? `${number}.${Leaves<U>}`
      : ""
    : {
        [K in keyof T]: `${K & string}${T[K] extends moment.Moment
          ? ""
          : T[K] extends object
          ? Leaves<T[K]> extends ""
            ? ""
            : `.${Leaves<T[K]>}`
          : ""}`;
      }[keyof T]
  : string;

// ExcludeDotEnding: Exclude paths ending with a dot
// * เนื่องจาก any[] มี dot (x.) เกินจึงต้อง filter ออก
type ExcludeDotEnding<T extends string> = T extends `${infer Prefix}.`
  ? never
  : T;

// ExtractedLeaves: Extract leaf paths of non-nullable properties
type ExtractedLeaves<S> = Leaves<NonNullableValues<S>>;

// NestedObjectLeaves: Exclude paths ending with a dot from extracted leaves
type NestedObjectLeaves<S> = ExcludeDotEnding<ExtractedLeaves<S>>;

// ProcessedLeaves: Extract path component using a separator
export type ProcessedLeaves<S> = SeparatedString<NestedObjectLeaves<S>>;

// RetTypeAtPath: Represents the value type of an object property based on a given path.
// export type RetTypeAtPath<T, S> = RetValueType<
//   PathSegmentArray<T & string>,
//   S
// >;
export type RetTypeAtPath<T, S> = NestedKeyLookup<T & string, S>;

// SetProperty: A function to set properties in an object based on a path
export type SetProperty<S> = <T extends ProcessedLeaves<S>>(
  path: T,
  value: RetTypeAtPath<T, S>,
  callback?: ()=> any
) => any;

export const DataInitial = {
  masterData: {},
  ...AppInitialize.DataInitial,
  ...ZoneLogI.DataInitial,
  ...SearchBoxI.DataInitial,
  ...RejectOrderI.DataInitial,
};

export const BLC_STATUS = {
  ERROR: "ERROR",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
} as const;

export const generatePrefixedActions = <T extends Record<string, string>, P extends string>(
  actions: T,
  prefix: P
): PrefixedActions<T, P> =>
  Object.fromEntries(
    Object.entries(actions).map(([key, value]) => [key, `${prefix}_${value}`])
  ) as PrefixedActions<T, P>;

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetMasterData: Handler = async (controller, params) => {
  const state = controller.getState();
  let masters = params?.masters || [];
  let filterMaster: any[] = [];
  let duplicatedMaster: any[] = [];
  let results: { [name: string]: any } = {};
  let masterOptions: { [name: string]: any } = {};
  const WAITING_TIME_SEC = 120;

  const BYPASS_DUP_LOGIC = false
  // prevent duplicate calling
  // check
  // store

  if (controller?.data?.masterData) {
    let alreadyGetterKeys = Object.keys(controller?.data?.masterData);

    if (!BYPASS_DUP_LOGIC) {
      // filterMaster initial
      // duplicateMaster initial
      filterMaster = masters.filter((m: [string, any]) => {
        if (alreadyGetterKeys?.includes(`${m?.[0]}Loading`)) {

          /// มี key Loadding
          let currentDate = new Date();
          let loadingTime = controller?.data?.masterData?.[`${m?.[0]}Loading`];

          if (loadingTime instanceof Date) {
            let diffSec = (currentDate.getTime() - loadingTime.getTime()) / 1000;
            if (diffSec > WAITING_TIME_SEC) {
              /// รอ API นานเกิน 2 นาที ให้เรียกซ้อนไปใหม่
              // console.debug(
              //   "GetMasterData รอ API นานเกิน 2 นาที ให้เรียกซ้อนไปใหม่",
              //   m?.[0]
              // );
              return true;
            } else {
              // ข้ามการเรียกซ้ำเนื่องจาก มี Date ใน Loading
              // console.debug(
              //   "GetMasterData ข้ามการเรียกซ้ำเนื่องจาก มี Date ใน Loading ",
              //   m?.[0]
              // );
              return false
            }
          }
        }

        // default เรียกตามปกติ ถ้าไม่ตรงเงื่อนไขด้านบน
        // console.debug(
        //   "GetMasterData default เรียกตามปกติ ถ้าไม่ตรงเงื่อนไขด้านบน",
        //   m?.[0]
        // );
        return true;
      });

      if ( !BYPASS_DUP_LOGIC ) {
        duplicatedMaster = masters?.filter(
          (m: any) => !filterMaster?.some((f: any) => m?.[0] === f?.[0])
        );

        let masterKeys = filterMaster.map((a: any) => a?.[0]);

        masterKeys.forEach((key: string) => {
          // console.log("GetMasterData !!!!! ADD Date into key: ", key , masterKeys)
          controller.data.masterData = {
            ...controller.data.masterData,
            [`${key}Loading`]: new Date()
          };
        });
      }

    } else {
      // logic เดิม
      filterMaster = [...masters]
    }
  }

  // console.log("GetMasterData--- masters: ", masters);
  // console.log("+++GetMasterData filterMaster: ", filterMaster);
  // console.log("+++GetMasterData duplicatedMaster: ", duplicatedMaster);
  // console.log("+++GetMasterData controller.data.masterData: ", controller.data.masterData);

  for (const master of filterMaster) {

    const masterMap = MasterMap?.[master[0]];
    // console.log("GetMasterData masterMap: ", masterMap);
    if (!masterMap || masterMap === null) {
      // console.log(
      //   "GetMasterData are null or masterMap are false not called api masterMap: ", masterMap
      // );
      continue;
    }

    if (Object.keys(state.masterOptions).includes(master[0])) {
      // console.log("+++GetMasterData already have masterOption not called api master[0]: ", master[0]);
      // Clear Flag
      if (!BYPASS_DUP_LOGIC && controller.data.masterData && `${master?.[0]}Loading` in controller.data.masterData) {
        // console.log("GetMasterData !!!!! Clear Date into key: ", master?.[0], filterMaster )
        delete controller.data.masterData?.[`${master?.[0]}Loading`]
      }
      continue;
    }
    const result = await masterMap.api({
      params: {
        ...masterMap.params,
        ...master[1],
      },
      apiToken: controller.apiToken,
    });

    // Clear Flag
    if (!BYPASS_DUP_LOGIC && controller.data.masterData && `${master?.[0]}Loading` in controller.data.masterData) {
      // console.log("GetMasterData !!!!! Clear Date into key: ", master?.[0], filterMaster )
      delete controller.data.masterData?.[`${master?.[0]}Loading`]
    }
    // console.log(`GetMasterData controller.data.masterData: `, controller.data.masterData)

    // Error clear
    if (result[1]) {
      console.error("masterMap : ",result[1])
      continue
    }

    // Success
    results[master[0]] = result[0]?.items || [];

    masterOptions[master[0]] = (result[0] || []).items?.map(
      (item: { [name: string]: any }) => ({
        key: item[masterMap.keyKey || masterMap.valueKey],
        value: item[masterMap.valueKey],
        text: item[masterMap.textKey],
        ...(masterMap?.relateKey ? { relate: item[masterMap.relateKey] } : {}),
      })
    );
  }

  controller.data.masterData = {
    ...controller.data.masterData,
    ...results,
  };

  controller.setState({
    masterOptions: {
      ...controller.getState().masterOptions,
      ...masterOptions,
    },
  });

  if (!BYPASS_DUP_LOGIC) {
    if (duplicatedMaster?.length > 0) {
      await new Promise((resolve, reject) => {
        let count = 1;
        (function checkingPending() {
          count += 1;
          let pending = false;
          // console.log('GetMasterData duplicatedMaster: ', duplicatedMaster);
          duplicatedMaster.forEach((d: any) => {
            if (controller.data.masterData?.[`${d?.[0]}Loading`] instanceof Date) {
              // console.log('GetMasterData controller.data.masterData?.[`${d?.[0]}Loading`]: ', controller.data.masterData?.[`${d?.[0]}Loading`]);
              pending = true;
            }
          });
          if (pending === false) {
            return resolve(true);
          }

          if (count > 1000) {
            return resolve(false);
          }
          // console.log(count, "Checking controller?.data?.masterDat: ", controller?.data?.masterData)
          setTimeout(checkingPending, 100);
        })();
      });
      // console.log(
      //   "GetMasterData--- after await GetMasterData controller?.data?.masterData: ",
      //   controller?.data?.masterData
      // );
    } else {
      // console.log(
      //   "GetMasterData--- no await GetMasterData controller?.data?.masterData: ",
      //   controller?.data?.masterData
      // );
    }
  }

};

export const Logout: Handler = async (controller, params) => {

  AppInitialize.clearLoginSession(controller, {} )
  // controller.apiToken = "";
  // if (!CONFIG.OFFLINE) {
  //   controller.app.auth().signOut();
  // }

  // Cookies.set("apiToken", "");
  // Cookies.set("division_id", "");
  // Cookies.set("device_id", "");
  // Cookies.set("user_id", "");
  // Cookies.remove("filterDrugOrderQueue");
  // Cookies.set("offlineMode", "online");

  // controller.data.provider = 0;

  controller.setState({
    django: {},
    isDoctor: false,
    isNurse: false,
    providerInfo: null,
    providerEmployeeInfo: null,
    userRoleList: [],
    divisionOptions: [],
    selectedDivision: null,
    loggedin: false,
  });

  controller.handleEvent({
    message: "RunSequence",
    params: { sequence: "AppInitialize" },
  });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
export const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );
  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

/**
 *
 * @param key // * หากต้องการกำหนด key แยกจาก value
 */
export const mapOptions = (
  items: (Record<string, any> | string)[],
  valueKey: string = "id",
  textKey: string = "name",
  key?: string
) => {
  return (items || []).map((value) => {
    const isString = typeof value === "string";
    return {
      key: isString ? value : key ? value[key] : value[valueKey],
      value: isString ? value : value[valueKey],
      text: isString ? value : value[textKey],
      original: value as any,
    };
  });
};

export const getWidth = (text: string, fontSize = 15) => {
  const combine = text ?? "";
  const div = document.createElement("div");

  div.setAttribute(
    "style",
    `width:fit-content;font-size:${fontSize}px;font-family:'THSarabunNew', sans-serif;white-space: nowrap;`
  );
  div.innerHTML = combine;
  document.body.appendChild(div);

  const width = div.offsetWidth;

  div.remove();

  return width;
};

const getCursorXY = (
  input: HTMLTextAreaElement,
  selectionPoint: number | number[]
) => {
  const { offsetLeft: inputX, offsetTop: inputY } = input;
  const div = document.createElement("div");
  const copyStyle: any = getComputedStyle(input);

  for (const prop of copyStyle) {
    div.style[prop] = copyStyle[prop];
  }

  const points = Array.isArray(selectionPoint)
    ? selectionPoint
    : [selectionPoint];
  const positions: { x: number; y: number }[] = [];

  for (const point of points) {
    const inputValue = input.value;
    const textContent = inputValue.substring(0, point);
    div.textContent = textContent;
    div.style.height = "auto";

    const span = document.createElement("span");
    span.textContent = inputValue.substring(point) || "";
    div.appendChild(span);
    document.body.appendChild(div);

    const { offsetLeft: spanX, offsetTop: spanY } = span;
    document.body.removeChild(div);

    positions.push({
      x: inputX + spanX,
      y: inputY + spanY,
    });
  }

  return positions;
};

const calculateStringNewLine = (
  text: string,
  options: Partial<{
    width: number;
    ellipse: string;
    fontSize: number;
    preWrap: boolean;
    wordBreak: boolean;
  }> = {}
) => {
  const textarea = document.createElement("textarea");
  const noWrap = options.preWrap ? "" : "white-space:nowrap;";
  
  let calText = text
  console.log('text: ', text);
  if (text === null || text === undefined) { 
    calText = " "
  }

  textarea.setAttribute(
    "style",
    `width:${options.width}px;font-size:${options.fontSize}px;font-family:'THSarabunNew', sans-serif;overflow: hidden;resize:none;${noWrap};position:fixed;left:0;padding:0`
  );
  textarea.innerHTML = calText;
  document.body.appendChild(textarea);
  

  const array = Array(calText.length)
    .fill("")
    .map((_, index) => index);

  const positions = getCursorXY(textarea, array);

  const matchedIndex = positions.reduce<{
    x: number;
    y: number;
    indexes: number[];
    end: boolean;
    stacks: any[];
  }>(
    (result, position, index, self) => {
      const { x, y } = position;
      let indexes: number[] = [];

      if (y !== result.y) {
        result.y = y;

        indexes = !result.indexes.length ? [0] : [index, index];
      } else if (options.width && x >= options.width && noWrap) {
        indexes = [index];

        result.end = true;
        array.splice(0);
      } else if (index === self.length - 1) {
        indexes = [self.length];
      }

      result.indexes.push(...indexes);
      result.stacks.push({ x, y, index, char: calText[index] });

      return result;
    },
    { x: 0, y: 0, indexes: [], end: false, stacks: [] }
  );

  textarea.remove();

  const indexes = matchedIndex.indexes;
  const mapIndexes = Array(Math.ceil(indexes.length / 2))
    .fill("")
    .map((_, index) => [indexes[index * 2], indexes[index * 2 + 1]]);

  const texts = mapIndexes.map((value) => calText.substring(value[0], value[1]));

  return { ...matchedIndex, texts, indexes: mapIndexes };
};

export const truncateString = (
  text: string,
  width = 230,
  options: Partial<{ ellipse: string; fontSize: number; preWrap: boolean }> = {}
) => {
  const { fontSize = 15 } = options;
  const ellipse = options.ellipse ?? "...";
  const ellipseWidth = getWidth(ellipse, fontSize);

  const calculated = calculateStringNewLine(text, { ...options, width });

  const stack = [...calculated.stacks]
    .reverse()
    .find((stack: any) => stack.x <= width - ellipseWidth);

  const result = calculated.end
    ? text.substring(0, stack?.index) + ellipse
    : calculated.texts[0];

  return { text: result, end: calculated.end };
};

export const splitStringNewLine = (
  text: string,
  options?: Partial<{
    lines: string[];
    width: number;
    fontSize: number;
    ellipse: string;
    max: number;
    preWrap: boolean;
  }>
): string[] => {
  const calculated = calculateStringNewLine(text, {
    ...options,
    ellipse: options?.ellipse ?? "",
    preWrap: options?.preWrap ?? true,
  });

  if (options?.max && calculated.indexes.length > options.max) {
    const index = calculated.indexes[options.max - 1];

    text = text.substring(index[0], text.length);

    const truncated = truncateString(text, options.width, {
      ...options,
      preWrap: false,
    });

    return [
      ...(calculated.texts.slice(0, options.max - 1) || ""),
      truncated.text,
    ];
  }

  return calculated.texts;
};

export const SetErrorMessage = (
  controller: any,
  params: {
    sequence?: string;
    action?: string;
    btnAction?: string;
    btnError?: any;
    card?: string;
    error?: any;
    errorKey?: string;
  }
) => {
  const state = controller.getState();

  const { sequence, action, btnAction, btnError, card, errorKey } = params;
  const buttonCard = card ? `${card}_` : "";
  const buttonSeq = sequence ? `${sequence}_` : "";
  const buttonKey =
    btnAction || action ? `${buttonCard || buttonSeq}${btnAction || action}` : card || sequence;

  const errKey = errorKey || card || sequence;

  let { error } = params;

  try {
    const decoder = new TextDecoder("utf8");
    const jsonString = decoder.decode(error);

    // Parse the string as JSON
    error = JSON.parse(jsonString);
  } catch (error) {
    console.error(error);
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [buttonKey || ""]: btnError ?? "ERROR",
    },
    errorMessage: {
      ...state.errorMessage,
      [errKey || ""]: error,
    },
  });
};

export const downloadXLSX = (result: ArrayBuffer, name: string)=> {
  // Convert ArrayBuffer to Blob
  const blob = new Blob([result], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create download link
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${name}.xlsx`;

  // Trigger the download
  link.click();

  link.remove();
}

export const downloadZipFile = (result: ArrayBuffer, filename: string)=> {
  // Convert ArrayBuffer to Blob
  const blob = new Blob([result], { type: "application/zip" });

  // Create download link
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;

  // Trigger the download
  link.click();

  link.remove();
}

export const getErrorMsgLabel = (error: any, required: Record<string, any>) => {
  if (!error) {
    return null;
  } else if (typeof error === "string") {
    return error;
  } else {
    return Object.entries(error).reduce(
      (result, [key, value]) => ({
        ...result,
        [required[key] || key]: value,
      }),
      {}
    );
  }
};

export const combinePdfFiles = async (forms: (TDocumentDefinitions | Uint8Array)[]) => {
  const createPDFBase64 = async (docDef: any): Promise<string> =>
    new Promise((resolve) => {
      pdfMake.createPdf(docDef).getBase64((result: any) => {
        resolve(result);
      });
    });

  const pdfMake = await getPdfMake(true);
  const pdfDoc = await PDFDocument.create();

  let pages: any[] = [];

  if (forms[0] instanceof Uint8Array) {
    pages = forms;
  } else {
    const promiseArr = forms.map(async (form) => createPDFBase64(form));
    pages = await Promise.all(promiseArr);
  }

  for (const page of pages) {
    const doc = await PDFDocument.load(page);
    const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

    for (const copyPage of copiedPages) {
      pdfDoc.addPage(copyPage);
    }
  }

  const base64Data = await pdfDoc.saveAsBase64();

  const blob = base64toBlob(`data:application/pdf;base64,${base64Data}`);

  return URL.createObjectURL(blob);
};

export const combineBase64Pdf = async (forms: string[]) => {
  const decodeBase64 = (base64: any) => Buffer.from(base64, 'base64');
  const encodeBase64 = (buffer: any) => buffer.toString('base64');

  const pdfBuffers = forms.map(decodeBase64);

  const mergedPdf = await PDFDocument.create();

  for (const pdfBuffer of pdfBuffers) {
    const pdf = await PDFDocument.load(pdfBuffer);
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    copiedPages.forEach((page: PDFPage) => mergedPdf.addPage(page));
  }

  const mergedPdfBase64 = await mergedPdf.saveAsBase64();

  return encodeBase64(mergedPdfBase64);
}

export const getLogoReport = async (type = 1, form = "") => {
  let logoInfo: { height: number; src: string; type: number; width: number }[] =
    CONFIG.LOGO_REPORT || [];

  logoInfo = Array.isArray(logoInfo) ? logoInfo : [logoInfo];

  let foundLogo = form ? await getLogoFormPath(form) : null;

  if (!foundLogo) {
    foundLogo = logoInfo.find((logo: any) => logo.type === type) || null;
  }

  return foundLogo || logoInfo[0] || {};
};

// #export const getResizedLogoWidth = (logoHeight: number, type = 1): number => {
//   const foundLogo = getLogoReport(type);
//   const height = foundLogo.height || 0;
//   const width = foundLogo.width || 0;
//   const logoResize = 1 - (height - logoHeight) / height;
//   return width * logoResize;
// };

export const getLogoReportNResize = async (
  logoHeight: number,
  type = 1,
  form = ""
): Promise<{ src: string; width: number }> => {
  const foundLogo = await getLogoReport(type, form);

  const height = foundLogo.height || 0;
  const width = foundLogo.width || 0;

  const logoResize = 1 - (height - logoHeight) / height;

  return { src: foundLogo.src, width: width * logoResize };
};

export const getResizedLogoHeight = async (logoWidth: number, type = 1): number => {
  const foundLogo = await getLogoReport(type);

  const height = foundLogo.height || 0;
  const width = foundLogo.width || 0;

  const logoResize = 1 - (width - logoWidth) / width;

  return height * logoResize;
};

export const getSideMenuIndex = ({
  divisionType,
  tab,
}: {
  divisionType?: string;
  tab?: string;
}) => {
  const defaultSideMenus = [
    "Dashboard",
    "Registration",
    "Encounter",
    "Appointment",
    "ChatSupport",
    "StaffChat",
    "MemberHealthKit"
  ];

  let sideMenus = defaultSideMenus;

  if (CONFIG.SIDEMENU_SCREEN_CONFIG) {
    const screenItems: any[] = CONFIG.SCREEN_ITEM || [];

    const filteredScreenItems = new Set(
      screenItems
        .map((screenItem) =>
          screenItem.extra ? (JSON.parse(screenItem.extra)?.key as string) : ""
        )
        .filter(Boolean)
    );

    sideMenus = sideMenus.filter((menu) => filteredScreenItems.has(menu));
  }

  if (divisionType === "backoffice") {
    sideMenus = ["Registration"];
  }

  let selectMenu = tab;

  if (!selectMenu && divisionType) {
    selectMenu =
      { backoffice: "Registration", ทั่วไป: "Registration" }[divisionType] || "Encounter";
  }

  const index = sideMenus.indexOf(selectMenu || "");

  return index === -1 ? 0 : index;
};

export const getDataURLFromURL = async (
  data: { [key: string]: any; image: string }[]
): Promise<any[]> => {
  const promiseArr = data.map(
    async (item) =>
      new Promise((resolve) => {
        if (!item.image) {
          resolve(item);

          return;
        }

        const img = document.createElement("img");

        img.crossOrigin = "Anonymous";
        img.src = item.image;

        img.onload = () => {
          resolve({ ...item, height: img.height, image: convertImageToPNG(img), width: img.width });
        };
      })
  );

  return Promise.all(promiseArr);
};

export const convertImageToPNG = (image: any) => {
  // create an off-screen canvas
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // set its dimension to target size
  canvas.width = image.width;
  canvas.height = image.height;

  // draw source image into the off-screen canvas:
  ctx?.drawImage(image, 0, 0, image.width, image.height);

  // encode image to data-uri with base64 version of the image
  try {
    return canvas.toDataURL("image/png");
  } catch (error) {
    console.log("Error: convertImageToPNG", error, image);

    return "";
  }
};
