// CORE
import AssureType from "issara-sdk/apis/AssureTypeList_core";
import CareerList from "issara-sdk/apis/CareerList_core";
import ChoiceView from "issara-sdk/apis/ChoiceView_core";
import CityList from "issara-sdk/apis/CityList_core";
import ClinicalTerm from "issara-sdk/apis/ClinicalTermList_core";
import ContactChannel from "issara-sdk/apis/ContactChannelList_core";
import CountryList from "issara-sdk/apis/CountryList_core";
import CoverageList from "issara-sdk/apis/CoverageList_core";
import RefererList from "issara-sdk/apis/RefererList_core";
import DistrictList from "issara-sdk/apis/DistrictList_core";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import DoctorList from "issara-sdk/apis/DoctorList_core";
import EducationList from "issara-sdk/apis/EducationList_core";
import HomeTypeList from "issara-sdk/apis/HomeTypeList_core";
import MaritalStatusList from "issara-sdk/apis/MaritalStatusList_core";
import Nationality from "issara-sdk/apis/NationalityList_core";
import PrenameList from "issara-sdk/apis/PrenameList_core";
import ProvinceList from "issara-sdk/apis/ProvinceList_core";
import ReligionList from "issara-sdk/apis/ReligionList_core";
import StandardAdministrationTimeList from "issara-sdk/apis/StandardAdministrationTimeList_core";
import ChoiceModelView from "issara-sdk/apis/ChoiceModelView_core";
import EncounterStatusList from "issara-sdk/apis/EncounterStatusList_core";
import ClinicalTermList from "issara-sdk/apis/ClinicalTermList_core";
import CleaningChoiceList from "issara-sdk/apis/CleaningChoiceList_core";
import SpecialEquipmentList from "issara-sdk/apis/SpecialEquipmentList_core";
import SpecialtyList from "issara-sdk/apis/SpecialtyList_core";
import PayerList from "issara-sdk/apis/PayerList_core";
import StorageList from "issara-sdk/apis/StorageList_core";
import UnitListCore from "issara-sdk/apis/UnitList_core";
import PackageTypeList from "issara-sdk/apis/PackageTypeList_core";
import PackageServiceTypeList from "issara-sdk/apis/PackageServiceTypeList_core";
import EnumGetterView from "issara-sdk/apis/EnumGetterView_core";
import ProductTypeList from "issara-sdk/apis/ProductTypeList_core";
import Icd9cmList from "issara-sdk/apis/Icd9cmList_core";
import Icd10List from "issara-sdk/apis/Icd10List_core";
// REG
import BloodTypeList from "issara-sdk/apis/BloodTypeList_apps_REG";
import InfoChannelList from "issara-sdk/apis/InfoChannelList_apps_REG";
import NicknameList from "issara-sdk/apis/NicknameList_apps_REG";
import NicknameReasonList from "issara-sdk/apis/NicknameReasonList_apps_REG";
// TPD
import FrequencyList from "issara-sdk/apis/PrescriptionFrequencyList_apps_TPD";
import MethodList from "issara-sdk/apis/PrescriptionMethodList_apps_TPD";
import RouteList from "issara-sdk/apis/PrescriptionRouteList_apps_TPD";
import SiteList from "issara-sdk/apis/PrescriptionSiteList_apps_TPD";
import UnitList from "issara-sdk/apis/UnitList_apps_TPD";
import UnitConversionList from "issara-sdk/apis/UnitConversionList_apps_TPD";
import MedicationErrorTypeList from "issara-sdk/apis/MedicationErrorTypeList_apps_TPD";
import IngredientList from "issara-sdk/apis/IngredientList_apps_TPD";
import DrugList from "issara-sdk/apis/DrugList_apps_TPD";
import DrugGroupList from "issara-sdk/apis/DrugGroupList_apps_TPD";
import DosageFormList from "issara-sdk/apis/DosageFormList_apps_TPD";
import PrescriptionPRNList from "issara-sdk/apis/PrescriptionPRNList_apps_TPD";
// ADM
import RoomStatus from "issara-sdk/apis/RoomSummaryStatus_apps_ADM";
import RoomTypeList from "issara-sdk/apis/RoomTypeList_apps_ADM";
import WardType from "issara-sdk/apis/WardTypeList_apps_ADM";
import AdmitOrderRoomItemStatusChoiceList from "issara-sdk/apis/AdmitOrderRoomItemStatusChoiceList_apps_ADM";
// PRX
import ShippingCompanyList from "issara-sdk/apis/ShippingCompanyList_apps_PRX";
import ShippingEmployeeList from "issara-sdk/apis/ShippingEmployeeList_apps_PRX";
// ORM
import AnesthesiaMethodList from "issara-sdk/apis/AnesthesiaMethodList_apps_ORM";
import OperatingLocationList from "issara-sdk/apis/OperatingLocationList_apps_ORM";
import OperatingRoomList from "issara-sdk/apis/OperatingRoomList_apps_ORM";
import PackingSiteList from "issara-sdk/apis/PackingSiteList_apps_ORM";
// BIL
import StationList from "issara-sdk/apis/StationList_apps_BIL";
import StationLogList from "issara-sdk/apis/StationLogList_apps_BIL";
import BillModeList from "issara-sdk/apis/BillModeList_apps_BIL";
// USERS
import PositionList from "issara-sdk/apis/PositionList_users";
import RoleList from "issara-sdk/apis/RoleList_users";
import ApplicationList from "issara-sdk/apis/ApplicationList_users";
import BankBranchList from "issara-sdk/apis/BankBranchList_users";
// LAB
import LabDivisionList from "issara-sdk/apis/LabDivisionList_apps_LAB";
// INF
import SentClaimChoiceList from "issara-sdk/apis/SentClaimChoiceList_apps_INF";
// FOD
import RegularMealList from "issara-sdk/apis/RegularMealList_apps_FOD";
// IME
import ImagingTestGroupList from "issara-sdk/apis/ImagingTestGroupList_apps_IME";
// DFC
import OfficerList from "issara-sdk/apis/OfficerList_apps_DFC";

// prettier-ignore
const MasterMap: {[name: string]: any} = {
  // Ishealth-v3 port into CUDent (vice versa)
  patientAppType:  {api: ClinicalTerm.list,    params: {type: "patient_appointment"}, valueKey: "id", textKey: "name", },
  waitingListItemStatus: { api: ChoiceView.get, params: { model: "QUE.WaitingListItem", field: "status"}, valueKey: "id", textKey: "value"},
  patientAppointmentStatus: { api: ChoiceView.get, params: { model: "QUE.PatientAppointment", field: "status"}, valueKey: "id", textKey: "label"},

  ADRProbability:  {api: ChoiceModelView.get,  params: {model: "ADR.AdverseReactionProbability"}, valueKey: "id", textKey: "name"} ,
  ADRSymptoms:     {api: ChoiceModelView.get,  params: {model: "ADR.AdverseReactionSymptom"}, valueKey: "id", textKey: "name"},
  ADRSeverity:     {api: ChoiceModelView.get,  params: {model: "ADR.AdverseReactionSeverity"} ,valueKey: "id", textKey: "name"},
  admitOrderStatus:{api: ChoiceView.get,       params: {model: "ADM.AdmitOrder", field: "status", name_as_id: true }, valueKey: "id", textKey: "label" },
  arriveStatus:    {api: ClinicalTerm.list,    params: {type: "PAB"}, valueKey: "id", textKey: "name"},
  assureType:      {api: AssureType.list,      params: {}, valueKey: "id", textKey: "name"},
  belief:          {api: ClinicalTerm.list,    params: {type: "SA"}, valueKey: "name", textKey: "name"},
  billingStatus:   {api: ChoiceView.get,       params: {model: "BIL.Invoice", field: "status"}, valueKey: "id",textKey: "label"},
  bloodType:       {api: BloodTypeList.list,   params: {}, valueKey: "id", textKey: "name"},
  career:          {api: CareerList.list,      params: {}, valueKey: "name", textKey: "name"},
  case:            {api: ClinicalTerm.list,    params: {type: "PC"}, valueKey: "id", textKey: "name"},
  characteristic:  {api: ClinicalTerm.list,    params: {type: "PCT"}, valueKey: "id", textKey: "name"},
  city:            {api: CityList.list,        params: {limit: 99999}, valueKey: "id", textKey: "name", relateKey: "area"},
  cleaningChoice:  {api: CleaningChoiceList.list, params: {}, valueKey: "id", textKey: "descriptive_name" },
  claimTreatment:  {api: ChoiceView.get,       params: {model: "TRT.TreatmentOrderItem", field: "eligibility_type"}, valueKey: "id",textKey: "label"},
  claimImaging:    {api: ChoiceView.get,       params: {model: "TRT.TreatmentOrderItem", field: "eligibility_type"}, valueKey: "value",textKey: "label"},
  conversion:      {api: UnitConversionList.list, params: {limit: 9999999}, valueKey: "id", textKey: "label"},
  contact:         {api: ContactChannel.list,  params: {}, valueKey: "id", textKey: "name"},
  country:         {api: CountryList.list,     params: {limit: 99999}, valueKey: "id", textKey: "full_name"},
  coverage:        {api: CoverageList.list,    params: {limit: 1000}, valueKey: "id", textKey: "code_name"},
  referer:         {api: RefererList.list,    params: {limit: 1000}, valueKey: "id", textKey: "name"},
  EncounterStatus: {api: EncounterStatusList.get, params: {type: "OPD"}, valueKey: "id", textKey: "text"},
  diagnosisType:   {api: ChoiceView.get,       params: {model: "core.Diagnosis", field: "type", name_as_id: true }, valueKey: "id", textKey: "label" },
  dischargeStatus: {api: ChoiceView.get,       params: {model: "DPI.DischargeSummary", field: "discharge_status", name_as_id: true }, valueKey: "id", textKey: "value" },
  disc_plan1 :     {api: ClinicalTerm.list,    params: {type:"disc_plan1"},valueKey: "id", textKey: "name"},
  disc_plan2 :     {api: ClinicalTerm.list,    params: {type:"disc_plan2"},valueKey: "id", textKey: "name"},
  disc_plan4 :     {api: ClinicalTerm.list,    params: {type:"disc_plan4"},valueKey: "id", textKey: "name"},
  disc_plan5 :     {api: ClinicalTerm.list,    params: {type:"disc_plan5"},valueKey: "id", textKey: "name"},
  disc_plan6 :     {api: ClinicalTerm.list,    params: {type:"disc_plan6"},valueKey: "id", textKey: "name"},
  disc_teaching1:  {api: ClinicalTerm.list,    params: {type:"disc_teaching1"},valueKey: "id", textKey: "name"},
  disc_teaching2:  {api: ClinicalTerm.list,    params: {type:"disc_teaching2"},valueKey: "id", textKey: "name"},
  disc_teaching3:  {api: ClinicalTerm.list,    params: {type:"disc_teaching3"},valueKey: "id", textKey: "name"},
  disc_teaching4:  {api: ClinicalTerm.list,    params: {type:"disc_teaching4"},valueKey: "id", textKey: "name"},
  disc_teaching5:  {api: ClinicalTerm.list,    params: {type:"disc_teaching5"},valueKey: "id", textKey: "name"},
  disc_teaching6:  {api: ClinicalTerm.list,    params: {type:"disc_teaching6"},valueKey: "id", textKey: "name"},
  disc_teaching7:  {api: ClinicalTerm.list,    params: {type:"disc_teaching7"},valueKey: "id", textKey: "name"},
  disc_teaching8:  {api: ClinicalTerm.list,    params: {type:"disc_teaching8"},valueKey: "id", textKey: "name"},
  disc_nursing1:   {api: ClinicalTerm.list,    params: {type:"disc_nursing1"},valueKey: "id", textKey: "name"},
  disc_nursing2:   {api: ClinicalTerm.list,    params: {type:"disc_nursing2"},valueKey: "id", textKey: "name"},
  disc_nursing3:   {api: ClinicalTerm.list,    params: {type:"disc_nursing3"},valueKey: "id", textKey: "name"},
  disc_nursing4:   {api: ClinicalTerm.list,    params: {type:"disc_nursing4"},valueKey: "id", textKey: "name"},
  disc_nursing5:   {api: ClinicalTerm.list,    params: {type:"disc_nursing5"},valueKey: "id", textKey: "name"},
  disc_nursing6:   {api: ClinicalTerm.list,    params: {type:"disc_nursing6"},valueKey: "id", textKey: "name"},
  district:        {api: DistrictList.list,    params: {limit: 99999}, valueKey: "id", textKey: "name", relateKey: "area"},
  division:        {api: DivisionList.list,    params: {limit: 1000}, valueKey: "id", textKey: "name"},
  divisionNameCode:{api: DivisionList.list,    params: {limit: 1000}, valueKey: "id", textKey: "name_code"},
  divisionIpd:     {api: DivisionList.list,    params: {for_ipd_encounter: true}, valueKey: "id", textKey: "name"},
  divisionOpd:     {api: DivisionList.list,    params: {for_opd_encounter: true }, valueKey: "id", textKey: "name_code" },
  divisionForOrderDiv:{api: DivisionList.list, params: {for_order_div: true, limit: 200 }, valueKey: "id", textKey: "name_code" },
  divisionSupply:  {api: DivisionList.list,    params: {type: "SUPPLY" }, valueKey: "id", textKey: "name" },
  divisionWard:    {api: DivisionList.list,    params: {type: "WARD" }, valueKey: "id", textKey: "name" },
  divisionDrug:    {api: DivisionList.list,    params: {type: "DRUG" }, valueKey: "id", textKey: "name" },
  divisionPharma:  {api: DivisionList.list,    params: {type: "DRUG" }, valueKey: "id", textKey: "name"},
  divisionTypeClinic: {api: DivisionList.list, params: {type: "CLINIC" }, valueKey: "id", textKey: "name" },
  divisionTypeDrug:{api: DivisionList.list,    params: {type: "DRUG" }, valueKey: "id", textKey: "name" },
  divisionType:    {api: ChoiceView.get,       params: {model: "core.Division", field: "type", name_as_id: true }, valueKey: "id", textKey: "label" },
  divisionTypeWithId: {api: ChoiceView.get,  params: {model: "core.Division", field: "type" }, valueKey: "id", textKey: "label" },
  doctor:          {api: DoctorList.list,      params: {limit: 99999}, valueKey: "id", textKey: "name_code"},
  doctorPendingTask:{api: ChoiceView.get,      params: {model: "DPO.DoctorPendingTask", field: "status"}, valueKey: "id", textKey: "value" },
  drugOrderType:   {api: ChoiceView.get,       params: {model: "TPD.DrugOrder", field: "type"}, valueKey: "id", textKey: "value"},
  drugOrderStatus: {api: ChoiceView.get,       params: {model: "TPD.DrugOrder", field: "status"}, valueKey: "value", textKey: "value"},
  drugStatus:      {api: ChoiceView.get,       params: {model: "TPD.DrugOrder", field: "status"}, valueKey: "id", textKey: "value"},
  duplicateReasons:{api: ClinicalTerm.list,    params: {type: "duplicate_reasons" }, valueKey: "id", textKey: "name" },
  education:       {api: EducationList.list,   params: {}, valueKey: "id", textKey: "name"},
  frequency:       {api: FrequencyList.list,   params: {limit: 9999999}, valueKey: "id", textKey: "label"},
  functional:      {api: ClinicalTerm.list,    params: {type: "FA"}, valueKey: "id", textKey: "name"},
  gender:          {api: ChoiceView.get,       params: {model: "core.Patient", field: "gender"}, valueKey: "id", textKey: "value"},
  homeType:        {api: HomeTypeList.list,    params: {}, valueKey: "id", textKey: "name"},
  infoChannel:     {api: InfoChannelList.list, params: {}, valueKey: "code", textKey: "name"},
  limitation:      {api: ClinicalTerm.list,    params: {limit: 300, type: "limitation"}, valueKey: "id", textKey: "name"},
  location:        {api: ClinicalTerm.list,    params: {type: "PLO"}, valueKey: "id", textKey: "name"},
  maritalStatus:   {api: MaritalStatusList.list,params: {},valueKey: "id", textKey: "name"},
  medErrorStatus:  {api: ChoiceView.get,       params: {model: "TPD.MedicationError", field: "status" }, valueKey: "id", textKey: "value"},
  medErrorRiskType:{api: ChoiceView.get,       params: {model: "TPD.MedicationError", field: "risk_type" }, valueKey: "id", textKey: "label"},
  medErrorCategory:{api: ChoiceView.get,       params: {model: "TPD.MedicationError", field: "category" }, valueKey: "id", textKey: "value"},
  medReconcileType:{api: ChoiceView.get,       params: {model: "TPD.MedicationReconciliation", field: "med_reconciliation_type"}, valueKey: "id", textKey: "text" },
  method:          {api: MethodList.list,      params: {limit: 9999999}, valueKey: "id", textKey: "label"},
  nationality:     {api: Nationality.list,     params: {limit: 99999}, valueKey: "id", textKey: "full_name"},
  nickname:        {api: NicknameList.list,    params: {}, valueKey: "id", textKey: "full_name"},
  nicknameActive:  {api: ClinicalTerm.list,    params: {type: "g"}, valueKey: "name", textKey: "name"},
  nicknamereason:  {api: NicknameReasonList.list,    params: {}, valueKey: "name", textKey: "name"},
  noVisitReason:   {api: ClinicalTerm.list,    params: {type: "no_visit_reason"}, valueKey: "id", textKey: "name"},
  operatingStatus: {api: ChoiceView.get,       params: {model: "ORM.OperatingOrder", field: "status"}, valueKey: "id", textKey: "label" },
  operatingType:   {api: ChoiceView.get,       params: {model: "ORM.OperatingOrder", field: "type"}, valueKey: "id", textKey: "label" },
  operatingRoom:   {api: OperatingRoomList.list, params: {}, valueKey: "id", textKey: "room_no"},
  orTransferLocation:{api: ChoiceView.get,     params: {model: "ORM.OperatingDetail", field: "transfer_location" }, valueKey: "id", textKey: "label" },
  pain:            {api: ClinicalTerm.list,    params: {type: "PAS"}, valueKey: "id", textKey: "name"},
  painFreq:        {api: ClinicalTerm.list,    params: {type: "PFC"}, valueKey: "id", textKey: "name"},
  patientCase:     {api: ClinicalTerm.list,    params: {type: "PATIENT_CASE"}, valueKey: "id", textKey: "name"},
  patientCondition:{api: ClinicalTerm.list,    params: {type: "H" }, valueKey: "id", textKey: "name" },
  patientDischarge:{api: ClinicalTerm.list,    params: {type: "I" }, valueKey: "id", textKey: "name" },
  patientEducation:{api: ClinicalTerm.list,    params: {type: "PATIENT_EDUCATION"}, valueKey: "id", textKey: "name"},
  patientIPDStatus:{api: AdmitOrderRoomItemStatusChoiceList.get,
                                               params: {}, valueKey: "id", textKey: "label"},
  payer:           {api: PayerList.list,       params: {disable_pagination: true}, valueKey: "id", textKey: "name_with_id" },
  planAndImpleHr:  {api: ClinicalTerm.list,    params: {type: "plan_and_imple_hr"}, valueKey: "id", textKey: "name"},
  planDischargeType:{api: ChoiceView.get,      params: {model: "core.MedicalRecord", field: "checkout_cause", name_as_id: true }, valueKey: "id", textKey: "value" },
  position:        {api: ClinicalTerm.list,    params: {type: "POS"}, valueKey: "name", textKey: "name"},
  prenameEn:       {api: PrenameList.list,     params: {language: 'EN', disable_pagination: true}, valueKey: "id", textKey: "name"},
  prenameTh:       {api: PrenameList.list,     params: {language: 'TH', disable_pagination: true}, valueKey: "id", textKey: "name"},
  province:        {api: ProvinceList.list,    params: {limit: 99999}, valueKey: "id", textKey: "name"},
  psychologicalStatus:{api: ClinicalTerm.list, params: {limit: 300, type: "psychological_status"}, valueKey: "id", textKey: "name"},
  race:            {api: ClinicalTerm.list,    params: {limit: 300, type: "RACE"}, valueKey: "id", textKey: "name"},
  readmissionType: {api: ChoiceView.get,       params: {model: "DPI.DischargeSummary", field: "readmission_type", name_as_id: true }, valueKey: "id", textKey: "value" },
  religion:        {api: ReligionList.list,    params: {}, valueKey: "id", textKey: "name"},
  respiratoryStatus:{api: ClinicalTerm.list,   params: {limit: 300, type: "respiratory_status"}, valueKey: "id", textKey: "name"},
  roomStatus:      {api: RoomStatus.list,      params: {}, valueKey: "id", textKey: "label"},
  roomType:        {api: RoomTypeList.list,    params: {}, valueKey: "id", textKey: "name"},
  route:           {api: RouteList.list,       params: {limit: 9999999}, valueKey: "id", textKey: "label"},
  shippingCompany: {api: ShippingCompanyList.list, params: {}, valueKey: "id", textKey: "name"},
  shippingEmployee:{api: ShippingEmployeeList.list, params: {}, valueKey: "id", textKey: "company_name" },
  site:            {api: SiteList.list,        params: {limit: 9999999}, valueKey: "id", textKey: "label"},
  standardAdminTime:{api: StandardAdministrationTimeList.list, params: {}, valueKey: "id", textKey: "name"},
  statusOfConscious:{api: ClinicalTerm.list,   params: {limit: 300, type: "status_of_conscious"}, valueKey: "id", textKey: "name"},
  supplyOrderStatus:{api: ChoiceView.get,      params: {model: "MSD.SupplyOrder", field: "status", name_as_id: true }, valueKey: "id", textKey: "id" },
  SupplyTransferStatus:{api: ChoiceView.get,      params: {model: "MSD.SupplyTransfer", field: "status", name_as_id: true }, valueKey: "id", textKey: "id" },
  unit:            {api: UnitList.list,        params: {limit: 9999999}, valueKey: "id", textKey: "label"},
  wardType:        {api: WardType.list,        params: {}, valueKey: "id", textKey: "name"},
  orType:          {api: ChoiceView.get,       params: {model: "ORM.OperatingOrder", field: "type", name_as_id: true}, valueKey: "id", textKey: "label" },
  orClinicType:    {api: ChoiceView.get,       params: {model: "ORM.OperatingOrder", field: "clinic_type", name_as_id: true}, valueKey: "id", textKey: "label" },
  anesthesiaMethod:{api: AnesthesiaMethodList.list, params: {}, valueKey: "id", textKey: "name" },
  orLocation:      {api: OperatingLocationList.list, params: {}, valueKey: "id", textKey: "name" },
  orProcedureType: {api: ChoiceView.get,       params: {model: "ORM.OperatingOrder", field: "procedure_type" }, valueKey: "id", textKey: "label" },
  orValuable:      {api: ClinicalTerm.list,    params: {limit: 300, type: "OR_VALUABLE" }, valueKey: "id", textKey: "name" },
  specialEquipment:{api: SpecialEquipmentList.list, params: {}, valueKey: "id", textKey: "name" },
  surgeonType:     {api: ClinicalTerm.list,    params: {limit: 300, type: "SURGEON_TYPE" }, valueKey: "id", textKey: "name" },
  orImplant:       {api: ClinicalTerm.list,    params: {limit: 300, type: "or_implant" }, valueKey: "id", textKey: "name" },
  otherTrtPosition:{api: ClinicalTerm.list,    params: {limit: 300, type: "other_trt_position" }, valueKey: "id", textKey: "name" },
  procedureType:   {api: ChoiceView.get,       params: {model: "core.Procedure", field: "type", name_as_id: true }, valueKey: "id", textKey: "label" },
  orPacuTo:        {api: ClinicalTerm.list,    params: {type: "PACU_TO"}, valueKey: "id", textKey: "name"},
  orPlanAndImple:  {api: ClinicalTerm.list,    params: {type: "plan_and_imple_sp_pd"}, valueKey: "id", textKey: "name"},
  orSurgicalCirculatory:{api: ClinicalTerm.list, params: {type: "surgical_circulatory"}, valueKey: "id", textKey: "name"},
  orSurgicalDevice:{api: ClinicalTerm.list,    params: {type: "surgical_device"}, valueKey: "id", textKey: "name"},
  orSurgicalPosition:{api: ClinicalTerm.list,  params: {type: "surgical_position"}, valueKey: "id", textKey: "name"},
  orSurgicalWarmer:{api: ClinicalTerm.list,    params: {type: "surgical_warmer"}, valueKey: "id", textKey: "name"},
  orTourniquetSide:{api: ClinicalTerm.list,    params: {type: "tourniquet_side"}, valueKey: "id", textKey: "name"},
  orGroundPadSite: {api: ClinicalTerm.list,    params: {type: "ground_pad_site"}, valueKey: "id", textKey: "name"},
  orSurgicalCautery:{api: ClinicalTerm.list,   params: {type: "surgical_cautery"}, valueKey: "id", textKey: "name"},
  orDefibrillatorSite:{api: ClinicalTerm.list, params: {type: "defibrillator_site"}, valueKey: "id", textKey: "name"},
  orSurgicalLaser: {api: ClinicalTerm.list,    params: {type: "surgical_laser"}, valueKey: "id", textKey: "name"},
  orVagina:        {api: ClinicalTerm.list,    params: {type: "OR_VAGINA"}, valueKey: "id", textKey: "name"},
  orMedication:    {api: ClinicalTerm.list,    params: {type: "OR_MEDICATION"}, valueKey: "id", textKey: "name"},
  orClinicalTerm:  {api: ClinicalTermList.list,params: {type: "catheterize_solution"}, valueKey: "id", textKey: "name"},
  orFoleyCathSize: {api: ClinicalTermList.list,params: {type: "foley_cath_size"}, valueKey: "id", textKey: "name"},
  orDrain:         {api: ClinicalTermList.list,params: {type: "OR_DRAIN"}, valueKey: "id", textKey: "name"},
  orDressing:      {api: ClinicalTermList.list,params: {type: "OR_DRESSING"}, valueKey: "id", textKey: "name"},
  orPlanNImplPacking:{api: ClinicalTerm.list,  params: {limit: 300, type: "plan_n_impl_packing"}, valueKey: "id", textKey: "name"},
  orPackingSite:   {api: PackingSiteList.list, params: {limit: 300}, valueKey: "id", textKey: "name"},
  orCancelNote:    { api: ClinicalTerm.list,   params: {type: "or_cancel_note" }, valueKey: "name", textKey: "name" },
  supplyType:      {api: ChoiceView.get,       params: {model: "MSD.SupplyOrder", field: "type" }, valueKey: "id", textKey: "value" },
  supplyStatus:    {api: ChoiceView.get,       params: {model: "MSD.SupplyOrder", field: "status", }, valueKey: "id", textKey: "value" },
  orIncisionClosure:{api: ClinicalTerm.list,   params: {type: "incision_closure" }, valueKey: "name", textKey: "name" },
  orDrainDetail:   {api: ClinicalTerm.list,    params: {type: "drain" }, valueKey: "name", textKey: "name" },
  bilStation:      {api: StationList.list,     params: {}, valueKey: "id", textKey: "name", relateKey: "device" },
  bilStationLog:   {api: StationLogList.list,  params: {}, valueKey: "id", textKey: "name" },
  discount:        {api: CoverageList.list,    params: {coverage_type:"DISCOUNT_CARD",disable_pagination:true}, valueKey: "id", textKey: "code_name"},
  positionAll:     {api: PositionList.list,    params: {}, valueKey: "id", textKey: "name"},
  specialty:       {api: SpecialtyList.list,   params: {}, valueKey: "id", textKey: "name"},
  role :           {api: RoleList.list,        params: {}, valueKey: "id", textKey: "name"},
  cleaningByName:  {api: CleaningChoiceList.list, params: {}, valueKey: "id", textKey: "name" },
  transferStatus:  {api: ChoiceView.get,       params: {model: "ADM.PlanTransfer", field: "status", }, valueKey: "id", textKey: "value" },
  transferUrgency: {api: ChoiceView.get,       params: {model: "ADM.PlanTransfer", field: "urgency", }, valueKey: "id", textKey: "value" },
  symptoms:        {api: ClinicalTermList.list,params: {type: "A"}, valueKey: "id", textKey: "name"},
  labDivision:     {api: LabDivisionList.list, params: {}, valueKey: "id", textKey: "name" },
  preanes_assessment_at: {api: ClinicalTermList.list,params: {type: "preanes_preanestheti"}, valueKey: "name", textKey: "name"},
  preanes_psychological_status: {api: ClinicalTermList.list,params: {type: "preanes_psychologica"}, valueKey: "name", textKey: "name"},
  preanes_previous:      {api: ClinicalTermList.list,params: {type: "preanes_previous"}, valueKey: "name", textKey: "name"},
  preanes_complication:  {api: ClinicalTermList.list,params: {type: "preanes_complication"}, valueKey: "name", textKey: "name"},
  preanes_known_disease: {api: ClinicalTermList.list,params: {type: "preanes_known_diseas"}, valueKey: "name", textKey: "name"},
  preanes_family_history:{api: ClinicalTermList.list,params: {type: "preanes_family_histo"}, valueKey: "name", textKey: "name"},
  preanes_current_med   :{api: ClinicalTermList.list,params: {type: "preanes_current_med"}, valueKey: "name", textKey: "name"},
  preanes_functional_class: {api: ClinicalTermList.list,params: {type: "preanes_functional"}, valueKey: "name", textKey: "name"},
  preanes_contact_lens:  {api: ClinicalTermList.list,params: {type: "preanes_contact_lens"}, valueKey: "name", textKey: "name"},
  preanes_mallampati:    {api: ClinicalTermList.list,params: {type: "preanes_mallampati"}, valueKey: "name", textKey: "name"},
  preanes_thyromental:   {api: ClinicalTermList.list,params: {type: "preanes_thyromental"}, valueKey: "name", textKey: "name"},
  preanes_mouth_opening: {api: ClinicalTermList.list,params: {type: "preanes_mouth_openin"}, valueKey: "name", textKey: "name"},
  preanes_patency_nares: {api: ClinicalTermList.list,params: {type: "preanes_patency"}, valueKey: "name", textKey: "name"},
  preanes_prominent:     {api: ClinicalTermList.list,params: {type: "preanes_prominent"}, valueKey: "name", textKey: "name"},
  preanes_limitation:    {api: ClinicalTermList.list,params: {type: "preanes_limitation"}, valueKey: "name", textKey: "name"},
  preanes_difficult:     {api: ClinicalTermList.list,params: {type: "preanes_difficult"}, valueKey: "name", textKey: "name"},
  preanes_respiratory:   {api: ClinicalTermList.list,params:{ type: "preanes_respiratory"}, valueKey: "name", textKey: "name"},
  preanes_cardiovascul:  {api: ClinicalTermList.list,params:{ type: "preanes_cardiovascul"}, valueKey: "name", textKey: "name"},
  preanes_neurologic:    {api: ClinicalTermList.list,params:{ type: "preanes_neurologic"}, valueKey: "name", textKey: "name"},
  preanes_EENT:          {api: ClinicalTermList.list,params:{ type: "preanes_EENT"}, valueKey: "name", textKey: "name"},
  preanes_gi:            {api: ClinicalTermList.list,params:{ type: "preanes_gi"}, valueKey: "name", textKey: "name"},
  preanes_genitourinar:  {api: ClinicalTermList.list,params:{ type: "preanes_genitourinar"}, valueKey: "name", textKey: "name"},
  preanes_Endocrine:     {api: ClinicalTermList.list,params: {type: "preanes_Endocrine"}, valueKey: "name", textKey: "name"},
  preanes_skin_breast:   {api: ClinicalTermList.list,params: {type: "preanes_skin_breast"}, valueKey: "name", textKey: "name"},
  preanes_Immunology:    {api: ClinicalTermList.list,params: {type: "preanes_Immunology"}, valueKey: "name", textKey: "name"},
  anes_plan_of_postope:  {api: ClinicalTermList.list,params: {type: "anes_plan_of_postope"}, valueKey: "name", textKey: "name"},
  preanes_plan_of_post:  {api: ClinicalTermList.list,params: {type: "preanes_plan_of_post"}, valueKey: "name", textKey: "name"},
  preanes_change_clini:  {api: ClinicalTermList.list,params: {type: "preanes_change_clini"}, valueKey: "name", textKey: "name"},
  preanes_change_in_cu:  {api: ClinicalTermList.list,params: {type: "preanes_change_in_cu"}, valueKey: "name", textKey: "name"},
  preanes_new_disease:   {api: ClinicalTermList.list,params: {type: "preanes_new_disease"}, valueKey: "name", textKey: "name"},
  pacu_adm_status:       {api: ClinicalTermList.list,params: {type: "pacu_adm_status"}, valueKey: "name", textKey: "name"},
  pacu_airway:           {api: ClinicalTermList.list,params: {type: "pacu_airway"}, valueKey: "name", textKey: "name"},
  anes_asa_ps:           {api: ClinicalTermList.list,params: {type: "anes_asa_ps"}, valueKey: "name", textKey: "name"},
  pacu_o2transfer:       {api: ClinicalTermList.list,params: {type: "pacu_o2transfer"}, valueKey: "name", textKey: "name"},
  pacu_ptctrlanal:       {api: ClinicalTermList.list,params: {type: "pacu_ptctrlanal"}, valueKey: "name", textKey: "name"},
  pacu_spiepimorph:      {api: ClinicalTermList.list,params: {type: "pacu_spiepimorph"}, valueKey: "name", textKey: "name"},
  peri_102:              {api: ClinicalTermList.list,params: {type: "peri_102"}, valueKey: "name", textKey: "name"},
  peri_103:              {api: ClinicalTermList.list,params: {type: "peri_103"}, valueKey: "name", textKey: "name"},
  peri_106:              {api: ClinicalTermList.list,params: {type: "peri_106"}, valueKey: "name", textKey: "name"},
  peri_108:              {api: ClinicalTermList.list,params: {type: "peri_108"}, valueKey: "name", textKey: "name"},
  peri_109:              {api: ClinicalTermList.list,params: {type: "peri_109"}, valueKey: "name", textKey: "name"},
  peri_203:              {api: ClinicalTermList.list,params: {type: "peri_203"}, valueKey: "name", textKey: "name"},
  peri_204:              {api: ClinicalTermList.list,params: {type: "peri_204"}, valueKey: "name", textKey: "name"},
  peri_205:              {api: ClinicalTermList.list,params: {type: "peri_205"}, valueKey: "name", textKey: "name"},
  peri_208:              {api: ClinicalTermList.list,params: {type: "peri_208"}, valueKey: "name", textKey: "name"},
  peri_209:              {api: ClinicalTermList.list,params: {type: "peri_209"}, valueKey: "name", textKey: "name"},
  peri_301:              {api: ClinicalTermList.list,params: {type: "peri_301"}, valueKey: "name", textKey: "name"},
  peri_302:              {api: ClinicalTermList.list,params: {type: "peri_302"}, valueKey: "name", textKey: "name"},
  peri_304:              {api: ClinicalTermList.list,params: {type: "peri_304"}, valueKey: "name", textKey: "name"},
  peri_305:              {api: ClinicalTermList.list,params: {type: "peri_305"}, valueKey: "name", textKey: "name"},
  peri_306:              {api: ClinicalTermList.list,params: {type: "peri_306"}, valueKey: "name", textKey: "name"},
  peri_308:              {api: ClinicalTermList.list,params: {type: "peri_308"}, valueKey: "name", textKey: "name"},
  peri_401:              {api: ClinicalTermList.list,params: {type: "peri_401"}, valueKey: "name", textKey: "name"},
  peri_402:              {api: ClinicalTermList.list,params: {type: "peri_402"}, valueKey: "name", textKey: "name"},
  peri_403:              {api: ClinicalTermList.list,params: {type: "peri_403"}, valueKey: "name", textKey: "name"},
  peri_405:              {api: ClinicalTermList.list,params: {type: "peri_405"}, valueKey: "name", textKey: "name"},
  peri_406:              {api: ClinicalTermList.list,params: {type: "peri_406"}, valueKey: "name", textKey: "name"},
  peri_504:              {api: ClinicalTermList.list,params: {type: "peri_504"}, valueKey: "name", textKey: "name"},
  peri_505:              {api: ClinicalTermList.list,params: {type: "peri_505"}, valueKey: "name", textKey: "name"},
  peri_702:              {api: ClinicalTermList.list,params: {type: "peri_702"}, valueKey: "name", textKey: "name"},
  peri_807:              {api: ClinicalTermList.list,params: {type: "peri_807"}, valueKey: "name", textKey: "name"},
  peri_9:                {api: ClinicalTermList.list,params: {type: "peri_9"}, valueKey: "name", textKey: "name"},
  peri_10:               {api: ClinicalTermList.list,params: {type: "peri_10"}, valueKey: "name", textKey: "name"},
  peri_12:               {api: ClinicalTermList.list,params: {type: "peri_12"}, valueKey: "name", textKey: "name"},
  peri_14:               {api: ClinicalTermList.list,params: {type: "peri_14"}, valueKey: "name", textKey: "name"},
  sentClaimChoice:       {api: SentClaimChoiceList.list, params: { field: "status" }, valueKey: "value", textKey: "label" },
  ansrec_preind_status:  {api: ClinicalTermList.list,params: {type: "ansrec_preind_status"}, valueKey: "name", textKey: "name"},
  ansrec_position:       {api: ClinicalTermList.list,params: {type: "ansrec_position"}, valueKey: "name", textKey: "name"},
  ansrec_monitors:       {api: ClinicalTermList.list,params: {type: "ansrec_monitors"}, valueKey: "name", textKey: "name"},
  ansrec_emergence:      {api: ClinicalTermList.list,params: {type: "ansrec_emergence"}, valueKey: "name", textKey: "name"},
  ansrec_bevel:          {api: ClinicalTermList.list,params: {type: "ansrec_bevel"}, valueKey: "name", textKey: "name"},
  ansrec_cmbi_gl_rl:     {api: ClinicalTermList.list,params: {type: "ansrec_cmbi_gl_rl"}, valueKey: "name", textKey: "name"},
  ansrec_airman_chkb1:   {api: ClinicalTermList.list,params: {type: "ansrec_airman_chkb1"}, valueKey: "name", textKey: "name"},
  ansrec_airman_chkb2:   {api: ClinicalTermList.list,params: {type: "ansrec_airman_chkb2"}, valueKey: "name", textKey: "name"},
  ansrec_airman_chkb3:   {api: ClinicalTermList.list,params: {type: "ansrec_airman_chkb3"}, valueKey: "name", textKey: "name"},
  ansrec_airman_chkb4:   {api: ClinicalTermList.list,params: {type: "ansrec_airman_chkb4"}, valueKey: "name", textKey: "name"},
  ansrec_airman_chkb5:   {api: ClinicalTermList.list,params: {type: "ansrec_airman_chkb5"}, valueKey: "name", textKey: "name"},
  ansrec_airman_tecint:  {api: ClinicalTermList.list,params: {type: "ansrec_airman_tecint"}, valueKey: "name", textKey: "name"},
  ansrec_airman_induct:  {api: ClinicalTermList.list,params: {type: "ansrec_airman_induct"}, valueKey: "name", textKey: "name"},
  ansrec_airman_ventil:  {api: ClinicalTermList.list,params: {type: "ansrec_airman_ventil"}, valueKey: "name", textKey: "name"},
  doctorType:            {api: ChoiceView.get,       params: {model: "core.Doctor", field: "type" }, valueKey: "id", textKey: "label" },
  medErrorType:          {api: MedicationErrorTypeList.list, params: {}, valueKey: "id", textKey: "name" },
  cancel_receipt:        {api: ClinicalTermList.list,params: {type: "R_CANCEL_RECEIPT"}, valueKey: "name", textKey: "name"},
  ingredient:            {api: IngredientList.list,  params: {show_on_adr: true }, valueKey: "id", textKey: "name" },
  drugName:              {api: DrugList.list,        params: {name: " ", limit: 2000}, valueKey: "id", textKey: "name" },
  drugGroup:             {api: DrugGroupList.list,   params: {}, valueKey: "id", textKey: "name" },
  activatePrivilege:     {api: ClinicalTermList.list,params: {type: "activate_privilege" }, valueKey: "name", textKey: "name" },
  suspendPrivilege:      {api: ClinicalTermList.list,params: {type: "suspend_privilege" }, valueKey: "name", textKey: "name" },
  billMode:              {api: BillModeList.list,    params: {}, valueKey: "id", textKey: "name" },
  storage:               {api: StorageList.list,     params: {}, valueKey: "id", textKey: "name" },
  dosageForm:            {api: DosageFormList.list,  params: {}, valueKey: "id", textKey: "name" },
  unitCore:              {api: UnitListCore.list,    params: {limit: 99999}, valueKey: "id", textKey: "name_en" },
  stockAddRef:           {api: ClinicalTerm.list,    params: {type: "STOCK_ADD_REF" }, valueKey: "name", textKey: "name" },
  stockReconcileNote:    {api: ClinicalTerm.list,    params: {type: "STOCK_RECONCILE_NOTE" }, valueKey: "name", textKey: "name" },
  patientType:           {api: ClinicalTermList.list,params: {type: "patient_type_tp" }, valueKey: "id", textKey: "name" },
  applications:          {api: ApplicationList.list, params: {}, valueKey: "id", textKey: "name" },
  postponeAppointment:   {api: ClinicalTerm.list,    params: {type: "POSTPONE_APP_REASON" }, valueKey: "name", textKey: "name" },
  regularMeal:           {api: RegularMealList.list, params: {}, valueKey: "id", textKey: "regular_time" },
  packageType:           {api: PackageTypeList.list, params: {}, valueKey: "id", textKey: "name" },
  packageServiceType:    {api: PackageServiceTypeList.list, params: {}, valueKey: "id", textKey: "name" },
  eligibilityType:       {api: ChoiceView.get,       params: {model: "MSD.SupplyOrderItem", field:"eligibility_type"}, valueKey: "id", textKey: "label" },
  cancelImagingOrder:    {api: ClinicalTerm.list,    params: {type: "CANCEL_IMAGING_ORDER" }, valueKey: "name", textKey: "name" },
  cancelPackageOrder:    {api: ClinicalTerm.list,    params: {type: "cancel_package_order" }, valueKey: "name", textKey: "name" },
  relative:              {api: ClinicalTerm.list,    params: {type: "F" }, valueKey: "name", textKey: "name" },
  imagingTestGroup:      {api: ImagingTestGroupList.list, params: {}, valueKey: "id", textKey: "name" },
  examinationType:       {api: EnumGetterView.get,   params: {enum_dotted_path: "his.core.models.EXAMINATION_TYPE", is_inner_class: false}, valueKey: "value", textKey: "label"},
  workgroup:             {api: ClinicalTerm.list,    params: {type: "media_doc_workgroup" }, valueKey: "name", textKey: "name" },
  drug_limited_age:      {api: ClinicalTerm.list,    params: {type: "drug_limited_age" }, valueKey: "name", textKey: "name" },
  officerList:           {api: OfficerList.list,     params: {},  valueKey: "id", textKey: "name"},
  bankBranch:            {api: BankBranchList.list,  params: {},  valueKey: "id", textKey: "full_name"},
  productType:           {api: ProductTypeList.list, params: {},  valueKey: "id", textKey: "name"},
  period:                {api: EnumGetterView.get,   params: {enum_dotted_path: "his.apps.INF.models.DFPayrollItem.PERIOD", is_inner_class: true}, valueKey: "name", textKey: "label"},
  workingType:           {api: EnumGetterView.get,   params: {enum_dotted_path: "his.apps.INF.models.DFPayrollItem.WORKING_TYPE", is_inner_class: true}, valueKey: "name", textKey: "label"},
  icd9cm:                {api: Icd9cmList.list,      params: {limit: 1000}, valueKey: "code", textKey: "term" },
  icd10:                 {api: Icd10List.list,       params: {limit: 1000}, valueKey: "code", textKey: "term" },
  diagnosisTypeValue:    {api: EnumGetterView.get,   params: {enum_dotted_path: "his.core.models.Diagnosis.TYPE", is_inner_class: true}, valueKey: "value", textKey: "label" },
  examinationTypeName:   {api: EnumGetterView.get,   params: {enum_dotted_path: "his.core.models.EXAMINATION_TYPE", is_inner_class: false}, valueKey: "name", textKey: "label"},
  divisionsTypeName:   {api: EnumGetterView.get,   params: {enum_dotted_path: "his.core.models.Division.TYPE", is_inner_class: true}, valueKey: "name", textKey: "label"},
  waitingListStatus:     {api: EnumGetterView.get,   params: {enum_dotted_path: "his.apps.QUE.models.WaitingListItem.STATUS", is_inner_class: true}, keyKey: "id", valueKey: "name", textKey: "label"},
  triageLevel:           {api: ChoiceView.get,  params: {model: "core.Triage", field: "triage_level", name_as_id: true}, valueKey: "id", textKey: "value"},
  prn:                   {api: PrescriptionPRNList.list, params: {limit: 9999999}, valueKey: "id", textKey: "text"},
  divisionSetStorage:    {api: DivisionList.list,    params: {set_storage: true }, valueKey: "id", textKey: "name" },
  sedation_score:        {api: ClinicalTermList.list,params: {type: "sedation_score"}, valueKey: "name", textKey: "name"},
  drugAllergyReason:     {api: ClinicalTermList.list,params: {type: "drug_allergy_reason"}, valueKey: "name", textKey: "name"},
  asa_class:             {api: ClinicalTermList.list,params: {type: "asa_class"}, valueKey: "id", textKey: "name"},
  episodeType:           {api: ChoiceView.get,       params: {model: "core.Episode", field: "episode_type", name_as_id: true }, valueKey: "id", textKey: "label" },
  resultSummary:         {api: EnumGetterView.get,   params: {enum_dotted_path: "his.apps.IME.models.ImagingOrderResult.SummaryResult"}, valueKey: "id", textKey: "name"},
  drugListing:           {api: EnumGetterView.get,   params: {enum_dotted_path: "his.apps.TPD.models.Drug.DRUG_LISTING"}, valueKey: "id", textKey: "label"}
}
export default MasterMap;
