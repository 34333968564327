import React, { useState, useMemo, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
// MUI
import makeStyles from "@mui/styles/makeStyles";

import { useLocation, useHistory } from "react-router";
import { useIntl } from "react-intl";

// UX
import BottomSheetCenterGroup from "./BottomSheetCenterGroup";
import CardListItem from "./CardListItem";
import ItemNotFound from "./ItemNotFound";
// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";

// Interface
import { Event, State } from "../MobSmartAppointmentInterface";

// Const.
import { URLS } from "./Constants";

// Types
type SelectCenterProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
} & Pick<State, "centerGroupList" | "appointmentCenterList" | "hospitalList">;

export type SCLocationType = {
  centergroup?: Record<string, any>;
  hospital?: Record<string, any>;
};

// Images
const IMAGES = {
  notfound: "/images/Appointment/no-information-search.png",
  neurosurgery: "/images/Appointment/neurosurgery.png",
};

// Styles
const COLORS = {
  light_blue_02: "rgba(1, 71, 163, 0.2)",
  light_blue_005: "rgba(1, 71, 163, 0.05)",
  half_blue: "rgba(1, 71, 163, 0.7)",
  light_grey: "rgba(121, 120, 120, 1)",
  grey: "rgba(57, 57, 57, 1)",
  icon: "rgba(159, 159, 159, 1)",
  shadow: "0px 4px 8px 0px rgba(44, 44, 44, 0.08)",
};

const dropdownStyles = {
  padding: "0 16px 16px",
  "& .ui.selection.dropdown": {
    padding: "15px 16px 15px 16px",
    borderRadius: "8px",
    border: `none`,
    backgroundColor: COLORS.light_blue_005,
    color: COLORS.grey,
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    opacity: 1,
    pointerEvents: "none",
    width: "100%",
    "& i": {
      color: COLORS.icon,
    },
  },
};

const useStyles = makeStyles(() => ({
  screen: {},
  dropdown: dropdownStyles,
  box_card: {
    background: "rgba(250, 250, 255, 1)",
    width: "calc(100% - 0px)",
    padding: "12px 16px",
    overflow: "auto",
    height: "calc(100vh - 9rem)",
    "& .MuiPaper-root": {
      boxShadow: COLORS.shadow,
      marginBottom: "0.85rem",
      border: "none",
    },
  },
}));

const params = new URLSearchParams(window.location.search);
const app = params.get("app");

const MOB_APP = `app=${app}`;

const SelectCenter = (props: SelectCenterProps) => {
  const classes = useStyles();
  const location = useLocation<SCLocationType>();
  const history = useHistory();
  const intl = useIntl();

  const [openModCenterGroup, setOpenModCenterGroup] = useState<boolean>(false);

  // Effect
  useEffect(() => {
      props.onEvent({
        message: "GetListAppointmentCenter",
        params: { lang: intl.locale, hospital: location.state?.hospital?.code },
      });
  }, [location]);

  useEffect(() => {
    if (!props.centerGroupList?.length) {
      props.onEvent({
        message: "GetListCenterGroup",
        params: { lang: intl.locale , hospital: location.state?.hospital?.code,},
      });
    }
  }, []);

  // Memo
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  // Memo
  const centergroup = useMemo(() => {
    return locState.centergroup;
  }, [locState]);

  const centerGroupOptions = useMemo(() => {
    return (props.centerGroupList || []).map((item) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }, [props.centerGroupList]);

  const centerItems = useMemo(() => {
    const items = props.appointmentCenterList || [];

    return items.filter((item) =>
      item.center_groups.includes(locState.centergroup?.id || "")
    );
  }, [props.appointmentCenterList, locState.centergroup]);

  const isNotFound = useMemo(() => {
    return (
      !centerItems.length &&
      !!props.appointmentCenterList?.length &&
      !!props.centerGroupList?.length
    );
  }, [props.appointmentCenterList, props.centerGroupList, centerItems]);

  // Handler
  const handleOpenModCenterGroup = () => {
    setOpenModCenterGroup(true);
  };

  const handleCloseModCenterGroup = () => {
    setOpenModCenterGroup(false);
  };

  const handleSelect = (selected: Record<string, any>) => {
    let url = URLS.SELECT_CHECKUP_HOSPITAL;
    props.setProp("textSearch","")

    if (centergroup?.appointment_type === "specialty") {
      url = URLS.SEARCH_DOCTOR;
    } else if (
      centergroup?.appointment_type === "check_up" &&
      locState.hospital?.code && 
      locState.hospital?.code != "-1"
    ) {
      url = URLS.SELECT_CHECKUP_TYPE;
    }

    history.push({
      pathname: `${url}/?${MOB_APP}`,
      state: { ...(location.state || {}), centergroup, center: selected },
    });
  };

  const handleSelectCenterGroup = (selected: Record<string, any>) => {
    history.replace({
      pathname: `${URLS.SELECT_CENTER}/?${MOB_APP}`,
      state: { ...(location.state || {}), centergroup: selected },
    });

    handleCloseModCenterGroup();
  };

  return (
    <div>
      <HeaderBar
        onEvent={() => {}}
        // data
        setTitle={
          centergroup?.appointment_type === "check_up"
            ? centergroup.name
            : "ความชำนาญของแพทย์"
        }
        whiteTheme={true}
        // config
        hiddenRight={true}
        hiddenLeft={true}
        buttonLeftback={true}
      />

      {centergroup?.appointment_type !== "check_up" && (
        <div
          aria-hidden="true"
          className={classes.dropdown}
          onClick={handleOpenModCenterGroup}
        >
          <Dropdown
            selection={true}
            fluid={true}
            icon="chevron down"
            options={centerGroupOptions}
            value={centergroup?.id || ""}
            disabled={true}
          />
        </div>
      )}

      <div className={classes.box_card}>
        {isNotFound ? (
          <ItemNotFound
            img={IMAGES.notfound}
            imgSize="6rem"
            subTitle={
              <>
                <div>
                  ไม่พบ Center สำหรับ
                  <div>
                    <span>“{locState.centergroup?.name}”</span>
                  </div>
                </div>
                <div>กรุณาเลือก Center group ใหม่</div>
              </>
            }
          />
        ) : (
          <CardListItem
            items={centerItems}
            hospitalList={props.hospitalList}
            // callback
            onSelect={handleSelect}
          />
        )}
      </div>

      <BottomSheetCenterGroup
        open={openModCenterGroup}
        items={props.centerGroupList || []}
        selected={centergroup?.id}
        isUseMinHight={true}
        // config
        search={true}
        // callnack
        onChange={handleCloseModCenterGroup}
        onSelect={handleSelectCenterGroup}
      />
    </div>
  );
};

export default React.memo(SelectCenter);
