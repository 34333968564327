import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { useHistory, useLocation } from "react-router";

// Ux
import DoctorBanner from "./DoctorBanner";
import ChipListItem from "./ChipListItem";
import { ARLocationType } from "./AppointmentReason";
import { CTLocationType } from "./SelectCheckupType";
// appointment
import AppCalendar, {
  BE_FORMAT_DATE,
  AppCalendarRef,
} from "../appointment/AppCalendar";
// telephar
import PaymentAlertMessage from "bplus-lib/telephar/PaymentAlertMessage";
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";
// register
import DialogReadMore from "bplus-lib/register/DialogReadMore";

// Interface
import { Event, State } from "../MobSmartAppointmentInterface";
import moment, { Moment } from "moment";
import BoxTitle from "./BoxTitle";

// Const.
import { URLS } from "./Constants";
import CircularProgress from "@mui/material/CircularProgress";
import ModBookingUnSuccess from "bplus-lib/appointment/ModBookingUnSuccess";
import { beToAd, momentADToLocalFormatBE } from "react-lib/utils/dateUtils";

// Types
type SelectDatetimeProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
} & Pick<
  State,
  | "calendarList"
  | "timeslotList"
  | "isLoadingCalendar"
  | "isLoadingNextMonth"
  | "loadingSkeleton"
  | "appointmentSlotList"
  | "menuTabAppointmentSlot"
  | "loadingStatus"
  | "successMessage"
  | "errorMessage"
  | "language"
>;

type TabMenuType = "morning" | "evening";
const EDIT_APPOINTMENT = "EditAppointment";

const ACTION_EDIT = `${EDIT_APPOINTMENT}_EDIT`;

export type SDLocationType = ARLocationType &
  ARLocationType["storedState"] &
  CTLocationType &
  CTLocationType["storedState"] & {
    editAppointment?: Record<string, any>;
    centergroup?: { appointment_type: any };
    checkup?: Record<string, any>;
    division?: Record<string, any>;
    appointmentLocation?: "on-site" | "online";
    doctor?: Record<string, any>;
    manualSelect?: boolean;
    storedState?: {
      slot?: {
        date: string;
        times: Record<string, any>[];
      };
      requestType?: "REALTIME" | "CRM";
    };
  };

// Images
const IMAGES = {
  request_app: "/images/Appointment/request-appointment.png",
};

// Styles
const COLORS = {
  grey: "rgba(57, 57, 57, 1)",
  shadow: ".0625rem .5rem 1.125rem 0rem rgba(0, 102, 255, 0.1)",
  half_shadow: "0rem .25rem .5rem 0rem rgba(44, 44, 44, 0.08)",
  primary: "var(--blue-bdms-color)",
  light_grey: "rgba(121, 120, 120, 1)",
};

const calendarStyles = {
  "& .calendar-custom": {
    boxShadow: COLORS.shadow,
    borderRadius: "1rem !important",
    "& .rc_header_nav": {
      boxShadow: `${COLORS.half_shadow} !important`,
    },
    "& .rc_header span": {
      color: `${COLORS.grey} !important`,
    },
    "& .rc_body-cell.rc_selected.rc_active .rc_body-cell_value": {
      background: `${COLORS.primary} !important`,
      borderRadius: `.5rem !important`,
      "&:before": {
        display: "none",
      },
      "& button": {
        color: `white !important`,
      },
    },
  },
};

const boxChipStyles = {
  marginTop: "16px",
  "& .MuiButton-root": {
    boxShadow: COLORS.half_shadow,
    border: "none",
    borderRadius: "1rem",
    padding: "10px 8px",
    "&.active": {
      fontWeight: "bold",
    },
  },
};

const modConfirmStyles = {
  "& div[class*=card_description]": {
    padding: "1.25rem 0.25rem .9375rem",
    "& .header": {
      textAlign: "center",
      fontWeight: "bold",
      color: COLORS.grey,
      fontSize: "1.375rem",
    },
    "& .label > label": {
      marginTop: ".1875rem",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  screen: {},
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    color: COLORS.grey,
    margin: "16px 0",
  },
  header: {
    textAlign: "center",
    fontWeight: "bold",
    color: COLORS.grey,
    fontSize: "1.375rem",
  },
  sub_title: {
    color: COLORS.light_grey,
    margin: "1.25rem 0 1.75rem ",
    textAlign: "center",
    fontWeight: "bold",
    width: "100%",
  },
  calendar: calendarStyles,
  box_chip: boxChipStyles,
  mod_confirm: modConfirmStyles,
}));

const SelectDatetime = (props: SelectDatetimeProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<SDLocationType>();

  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [disableTimeSlot, setDisableTimeSlot] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<string[]>([]);
  // opent
  const [openModConfirmEdit, setOpenModConfirmEdit] = useState<boolean>(false);
  const [openModWaitingConfirm, setOpenModWaitingConfirm] =
    useState<boolean>(false);
  const [openModSelectNewTime, setOpenModSelectNewTime] =
    useState<boolean>(false);
  const [slotDifference, setSlotDifference] = useState<number>(0);

  const today = moment();

  const calendarRef = useRef<AppCalendarRef>();

  // Memo
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  const storedState = useMemo(() => {
    return locState.storedState;
  }, [locState]);

  const isLoading = useMemo(() => {
    return props.loadingStatus?.[ACTION_EDIT];
  }, [props.loadingStatus]);

  const errorMessage = useMemo(() => {
    return props.errorMessage?.[ACTION_EDIT];
  }, [props.errorMessage]);

  const isDateBE = useMemo(() => {
    return props.language === "th-TH" ? true : false;
  }, [props.language]);

  // Callback
  const handleGetAppointmentSlot = useCallback(() => {
    const timeslot = (props.timeslotList || []).find(
      (item: any) => item.raw_date === selectedDate?.format(BE_FORMAT_DATE)
    );
    const date = selectedDate?.format(BE_FORMAT_DATE) || "";

    if (!timeslot?.location) {
      return;
    }

    props.onEvent({
      message: "GetAppointmentSlot",
      params: {
        loc_code: locState?.division?.division_code || timeslot?.location || "",
        start_date: date,
        end_date: date,
        doctor_code:
          locState.checkup?.doctor_code || timeslot?.doctor?.doctor_code,
        appointment_type: isCheckup ? "check_up" : "specialty",
        is_telemed: locState.appointmentLocation === "online",
      },
    });
  }, [selectedDate]);

  const mapTimeSlot = useCallback((item: any) => {
    const time = `${item.start_time}-${item.end_time}`;

    return {
      ...item,
      id: item.start_time,
      value: time,
    };
  }, []);

  useEffect(() => {
    if (selectedDate) {
      handleGetAppointmentSlot();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (storedState) {
      const times = (storedState.slot?.times || []).map((item) => item.id);

      setSelectedDate(moment(storedState.slot?.date));
      setSelectedTime(times.filter(Boolean));
    }
  }, [locState.storedState]);

  useEffect(() => {
    const edit = locState.editAppointment;

    if (!storedState && edit) {
      setSelectedTime([edit.start_time]);
    }
  }, [locState.storedState, locState.editAppointment]);

  // Memo
  const timeSlots = useMemo(() => {
    return (props.appointmentSlotList?.time_slots || []).map(
      mapTimeSlot
    ) as any[];
  }, [props.appointmentSlotList]);

  const isOnSite = useMemo(() => {
    return locState.appointmentLocation === "on-site";
  }, [locState.appointmentLocation]);

  const handleCheckTimeSlot = () => {

    let morningSlot = (props.appointmentSlotList?.["morning"] || []).map(
      mapTimeSlot
    ) as any[];

    let eveningSlot = (props.appointmentSlotList?.["evening"] || []).map(
      mapTimeSlot
    ) as any[];

    var listDisable = disableTimeSlot;
    if (morningSlot.length > 0) {
      listDisable = listDisable.filter((item: any) => item != "morning");
    } else {
      if (!listDisable.includes("morning")) {
        listDisable.push("morning");
      }
    }

    if (eveningSlot.length > 0) {
      listDisable = listDisable.filter((item: any) => item != "evening");
    } else {
      if (!listDisable.includes("evening")) {
        listDisable.push("evening");
      }
    }

    setDisableTimeSlot(listDisable);
  };

  const items = useMemo(() => {
    handleCheckTimeSlot();
    const isSlot = (
      props.appointmentSlotList?.[props.menuTabAppointmentSlot || ""] || []
    ).map(mapTimeSlot) as any[];
      if (isSlot?.[0]?.raw_end_time) {
        const start = moment.utc(isSlot[0].raw_start_time, 'HH:mm:ssZ');
        const end = moment.utc(isSlot[0].raw_end_time, 'HH:mm:ssZ');
        const differenceInMinutes = moment.duration(end.diff(start)).asMinutes();
        setSlotDifference(differenceInMinutes*2)
      } else {
        setSlotDifference(0)
      }

    return isSlot
  }, [props.menuTabAppointmentSlot, props.appointmentSlotList]);

  const isCheckup = useMemo(() => {
    return locState.centergroup?.appointment_type === "check_up";
  }, [locState.centergroup]);

  const specialtyName = useMemo(() => {
    return locState.doctor?.specialties;
  }, [locState.doctor]);

  const allowConfirm = useMemo(() => {
    const isSelectedTime = timeSlots.find((item) =>
      selectedTime.includes(item.id)
    );

    return (
      (locState.reason?.first_consultation && isOnSite
        ? selectedTime.length === 2
        : selectedTime.length) && isSelectedTime
    );
  }, [locState.reason, selectedTime, timeSlots]);

  const appointmentDatetime = useMemo(() => {
    const times = timeSlots.filter((item) => selectedTime.includes(item.id));

    const startTime = times[0]?.start_time || "";
    const endTime = times.slice(-1)[0]?.end_time || "";

    return selectedDate
      ? `${moment(selectedDate).format("DD MMM YYYY")}|${startTime}-${endTime}`
      : "";
  }, [selectedDate, selectedTime]);

  const hospitalName = useMemo(() => {
    return locState.doctor?.hospital_name || locState.hospital?.name || "";
  }, [locState.doctor, locState.hospital]);

  const firstTime = useMemo(() => {
    return locState.reason?.first_consultation;
  }, [locState.reason]);

  const headerImage = useMemo(() => {
    return isCheckup ? locState.checkup?.image : locState.doctor?.image;
  }, [isCheckup, locState]);

  // Handler
  const getCalendars = async (isTelemed: boolean) => {
    calendarRef.current?.getCalendar({
      isTelemed,
      appointmentType: locState.centergroup?.appointment_type,
    });
  };

  const handleOnLoad = () => {
    if (!storedState?.slot || !props.appointmentSlotList?.time_slots) {
      getCalendars(locState.appointmentLocation === "online");
    } else {
      calendarRef.current?.updateYearMonthList(moment(storedState.slot?.date));
    }
  };

  const handleGetCalendar = (dateRange: any[], isTelemed: boolean) => {
    props.onEvent({
      message: "GetCalendars",
      params: {
        doctor_code:
          locState.checkup?.doctor_code ||
          locState.doctor?.employee_code ||
          locState.doctor?.code,
        date_range: dateRange,
        is_telemed: isTelemed,
        loc_code:
          locState.checkup?.location_code || locState?.division?.division_code,
        hospital: locState.hospital?.code,
      },
    });
  };

  const handleDateChange = (date: Moment | null) => {
    // if (isDateBE && date) {
    //   const dateBE = beToAd(moment(date).format("DD/MM/YYYY"));
    //   setSelectedDate(moment(dateBE));
    // } else {
    //   setSelectedDate(date);
    // }
    setSelectedDate(date);
    setSelectedTime([]);
  };

  const handleChangeDataRangeBEtoAD = (dateRange: any[]) => {
    return dateRange.map((items) => {
      const startAD = beToAd(moment(items.start_date).format("DD/MM/YYYY"));
      const startADString = moment(startAD).format(BE_FORMAT_DATE);
      const endAD = beToAd(moment(items.end_date).format("DD/MM/YYYY"));
      const endADString = moment(endAD).format(BE_FORMAT_DATE);
      const currentYear = moment().format("YYYY");
      if (
        (startADString || "").includes(currentYear) ||
        (startADString || "").includes(`${parseInt(currentYear) + 1}`)
      ) {
        return {
          start_date: startADString,
          end_date: endADString,
        };
      } else {
        return items;
      }
    });
  };

  const handleChangeTab = (newTab: TabMenuType) => (e: any) => {
    if (!props.loadingSkeleton && !disableTimeSlot.includes(newTab)) {
      props.setProp("menuTabAppointmentSlot", newTab);
    }
  };

  const handleRightIconClick = () => {
    history.goBack();
  };

  const handleSelectTime = (selected: any) => {
    let indexTime = items.findIndex((item: any) => item.id === selected.id);

    let time = [...selectedTime];

    if (firstTime && isOnSite) {
      if (items.length >= 2) {
        const latType = disableTimeSlot.includes("evening")
          ? "morning"
          : "evening";

        if (indexTime + 1 === items.length) {
          // && props.menuTabAppointmentSlot === latType ) {
          // indexTime -= 1;
          return setOpenModSelectNewTime(true);
        }

        const itemSelected = items.slice(indexTime, indexTime + 2);
        time = [];
        time = itemSelected.map((items) => {
          return items.id;
        });
      } else {
        return setOpenModSelectNewTime(true);
        // console.log("firstTime slot less 1 item can't select:", time);
        // const include = time.includes(selected.id);

        // if (include) {
        //   time = time.filter((id) => id !== selected.id);
        // } else {
        //   time.unshift(selected.id);
        // }

        // time = time.slice(0, firstTime ? 2 : 1);
      }
    } else {
      const include = time.includes(selected.id);
      if (include) {
        time = time.filter((id) => id !== selected.id);
      } else {
        time.unshift(selected.id);
      }

      time = time.slice(0, 1);
    }

    setSelectedTime([...time]);
  };

  const handleConfirmAppointment = () => {
    let pathname = "";
    let cutOff = "";
    let manualSelect = false;
    const times = items.filter((item) => selectedTime.includes(item.id));

    if (locState.editAppointment) {
      if (!locState.editAppointment.realtime) {
        setOpenModWaitingConfirm(true);
      } else {
        setOpenModConfirmEdit(true);
      }

      return;
    } else if (isCheckup) {
      pathname = URLS.CONFIRM_APPOINTMENT;
    } else {
      if (isOnSite && times[0]?.online) {
        pathname = URLS.CONFIRM_APPOINTMENT;
      } else if (isOnSite) {
        pathname = URLS.HEALTH_PROBLEM;
      } else {
        pathname = URLS.HEALTH_PROBLEM;
        cutOff = URLS.HEALTH_PROBLEM;
        manualSelect = true;
      }
    }

    if (firstTime && isOnSite) {
      const endTime = times[0].end_time;
      const startTime = times[1].start_time;

      if (endTime !== startTime) {
        return setOpenModSelectNewTime(true);
      }
    }

    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history,
        pathname,
        cutOff: cutOff,
        state: {
          slot: {
            date: moment(selectedDate).toISOString(),
            times: times,
          },
          requestType: times[0]?.online ? "REALTIME" : "CRM",
          ...(manualSelect && { manualSelect: manualSelect }),
        },
      },
    });
  };

  const handleConfirmEditAppointment = () => {
    const times = items.filter((item) => selectedTime.includes(item.id));

    if (firstTime && isOnSite) {
      const endTime = times[0].end_time;
      const startTime = times[1].start_time;

      if (endTime !== startTime) {
        return setOpenModSelectNewTime(true);
      }
    }

    props.onEvent({
      message: "HandleEditAppointment",
      params: {
        history,
        card: ACTION_EDIT,
        slot: {
          date: times?.[0].raw_date,
          slot_id: times?.[0].slot_id,
          time: times?.[0].start_time,
        },
      },
    });
  };

  const handleClearErrorMessage = async () => {
    const { [ACTION_EDIT]: ae, ...error } = props.errorMessage || {};

    await props.setProp(`errorMessage`, { ...error });
  };

  const handleGetNextMonth = async (dateRange: any[]) => {
    // -const locCode = props.params.locCode;

    return props.onEvent({
      message: "handleCalendarNextMonth",
      params: {
        date_range: dateRange,
        loc_code: locState.checkup?.location_code,
        doctor_code:
          locState.checkup?.doctor_code ||
          locState.doctor?.employee_code ||
          locState.doctor?.code,
        is_telemed: locState.appointmentLocation === "online",
        hospital: locState.hospital?.code,
      },
    });
  };

  const handleConfirmEdit = () => {
    handleConfirmEditAppointment();
  };

  const handleCloseModWaitingConfirm = () => {
    setOpenModWaitingConfirm(false);
  };

  const handleCloseModSelectNewTime = () => {
    setOpenModSelectNewTime(false);
  };

  const handleCheckDateBe = (date?: Date | Moment | null) => {
    if (!date) {
      return null;
    }

    if (isDateBE && date) {
      const dateBE = momentADToLocalFormatBE(moment(date));
      return moment(dateBE);
    } else {
      const dateBE = momentADToLocalFormatBE(moment(date));
      return date;
    }
  };

  const handleCheckCalendarListBE = (calendarList: any) => {

    if (calendarList?.active.length < 1) {
      return calendarList;
    }

    if (isDateBE) {

      const active = calendarList?.active.map((item: any) => {
        const dateBE = momentADToLocalFormatBE(moment(item));
        const activeDate = new Date(
          moment(dateBE).locale(`th`).format(BE_FORMAT_DATE)
        );
        activeDate.setHours(0, 0, 0, 0);
        return activeDate;
      });

      const inActive = calendarList?.inactive.map((item: any) => {
        const dateBE = momentADToLocalFormatBE(moment(item));
        const inactiveDate = new Date(
          moment(dateBE).locale(`th`).format(BE_FORMAT_DATE)
        );
        inactiveDate.setHours(0, 0, 0, 0);
        return inactiveDate;
      });
      
      return { active: active, inactive: inActive };
    } else {
      return calendarList;
    }
  };

  console.log("SelectDatetime", props);

  return (
    <DoctorBanner
      headerName={
        locState.editAppointment
          ? "แก้ไขวันเวลาการนัดหมาย"
          : "วันและเวลาการนัดหมาย"
      }
      titleName={
        isCheckup ? locState.checkup?.name : locState.doctor?.full_name
      }
      specialtyName={specialtyName}
      appLoc={locState.appointmentLocation}
      appointmentType={
        locState.centergroup?.appointment_type === "check_up"
          ? "check_up"
          : "doctor"
      }
      headerImage={headerImage}
      hospitalName={hospitalName}
      // callback
      rightIconClick={
        locState.editAppointment ? handleRightIconClick : undefined
      }
    >
      <div className={classes.screen}>
        {!isCheckup && (
          <BoxTitle
            style={
              locState.division?.price
                ? { gridTemplateColumns: "1fr auto" }
                : {}
            }
          >
            <>
              <div>{locState.division?.name || "-"}</div>
              {!!locState.division?.price &&
                locState.appointmentLocation != "on-site" && (
                  <div className="price">฿ {locState.division?.price}</div>
                )}
            </>
          </BoxTitle>
        )}

        {locState.editAppointment && (
          <BoxTitle
            style={{
              gridTemplateColumns: "1fr 1fr",
              margin: "12px 0 16px",
            }}
          >
            <>
              <div className="divider">
                <div className="normal">รูปแบบการนัดหมาย</div>
                <div>{locState.editAppointment.med_service_name}</div>
              </div>
              <div>
                <div className="normal">ชื่อคนไข้ </div>
                <div>{locState.editAppointment?.patient_name || ""}</div>
              </div>
            </>
          </BoxTitle>
        )}

        <div className={classes.title}>เลือกวัน</div>

        <AppCalendar
          ref={calendarRef}
          className={classes.calendar}
          language={props.language}
          // data
          value={selectedDate}
          defaultValue={!locState.storedState ? locState.editAppointment?.date : null}
          calendarList={props.calendarList}
          timeslotList={props.timeslotList}
          isLoadingCalendar={props.isLoadingCalendar}
          isLoadingNextMonth={props.isLoadingNextMonth}
          // callback
          onChange={handleDateChange}
          onNextMonth={handleGetNextMonth}
          onGetCalendar={handleGetCalendar}
          onLoad={handleOnLoad}
        />

        {selectedDate && (
          <>
            <div className={classes.title} style={{ marginTop: "24px" }}>
              เลือกเวลา
            </div>
            <div className="smart-tabs-custom">
              <div
                aria-hidden="true"
                className={
                  props.menuTabAppointmentSlot === "morning" ? "active" : ""
                }
                style={{
                  opacity: disableTimeSlot.includes("morning") ? "0.5" : "1",
                }}
                onClick={handleChangeTab("morning")}
              >
                ช่วงเช้า
              </div>
              <div
                aria-hidden="true"
                className={
                  props.menuTabAppointmentSlot === "evening" ? "active" : ""
                }
                style={{
                  opacity: disableTimeSlot.includes("evening") ? "0.5" : "1",
                }}
                onClick={handleChangeTab("evening")}
              >
                ช่วงบ่าย
              </div>
            </div>

            <ChipListItem
              className={classes.box_chip}
              items={items}
              selected={selectedTime}
              minWidth={90}
              highlight={true}
              loading={props.loadingSkeleton}
              // callback
              onSelect={handleSelectTime}
            />
          </>
        )}

        {firstTime && selectedDate && isOnSite && (
          <PaymentAlertMessage
            type="info-blue"
            style={{ padding: ".3125rem 1rem", marginTop: "20px" }}
          >
            {`การพบแพทย์ครั้งแรกใช้เวลา ${slotDifference} นาที`}
          </PaymentAlertMessage>
        )}

        {locState.editAppointment && !locState.editAppointment.realtime && (
          <PaymentAlertMessage
            type="info-yellow"
            style={{ padding: ".75rem 1rem", marginTop: "20px" }}
          >
            การส่งคำขอแก้ไขนัดหมายต้องรอการยืนยัน นัดหมายจากโรงพยาบาล ภายใน 24
            ชั่วโมง
          </PaymentAlertMessage>
        )}

        <div className="button-submit-bottom" style={{ marginTop: "36px" }}>
          <MuiButton
            variant="contained"
            color="primary"
            disabled={!allowConfirm}
            onClick={handleConfirmAppointment}
          >
            {locState.editAppointment && !locState.editAppointment.realtime
              ? "ส่งคำขอแก้ไขนัดหมาย"
              : "ถัดไป"}
          </MuiButton>
        </div>

        <DialogReadMore
          className={classes.mod_confirm}
          open={openModConfirmEdit}
          centered={true}
          hideCloseIcon={true}
          onChange={setOpenModConfirmEdit}
          dialogStyle={{ padding: "20px 10px" }}
        >
          <>
            <div className={classes.header}>ยืนยันแก้ไขการนัดหมาย</div>
            <div className={classes.sub_title}>วันเวลาในการนัดหมายของท่าน</div>

            <BoxTitle
              type="datetime"
              datetime={appointmentDatetime}
              style={{
                marginBottom: "16px",
                minHeight: "60px",
                padding: "6.5px 10px",
              }}
              labelStyle={{ fontSize: "16px" }}
            />

            <div className="button-submit-bottom" style={{ marginTop: "32px" }}>
              <MuiButton
                variant="contained"
                color="primary"
                // disabled={true}
                onClick={handleConfirmEdit}
              >
                <>
                  {"ยืนยัน"}
                  {isLoading && (
                    <CircularProgress
                      style={{
                        marginLeft: "0.2rem",
                      }}
                      size={16}
                    />
                  )}
                </>
              </MuiButton>
            </div>

            <div
              onClick={() => setOpenModConfirmEdit(false)}
              style={{
                textAlign: "center",
                color: COLORS.primary,
                marginBottom: "16px",
              }}
            >
              ยกเลิก
            </div>
          </>
        </DialogReadMore>

        <DialogReadMore
          className={classes.mod_confirm}
          open={openModSelectNewTime}
          centered={true}
          hideCloseIcon={true}
          onChange={setOpenModSelectNewTime}
        >
          <>
            <div className={classes.header}>ไม่สามารถเลือกเวลานี้ได้</div>
            <div
              className={classes.sub_title}
              style={{ textAlign: "center", margin: "1rem 0 0" }}
            >
              <div>เนื่องจากการพบแพทย์ครั้งแรก</div>
              <div>{`ใช้เวลา ${slotDifference} นาที`}</div>
            </div>

            <div
              className="button-submit-bottom"
              style={{ margin: "2rem 0 -5px" }}
            >
              <MuiButton
                variant="contained"
                color="primary"
                // disabled={true}
                onClick={handleCloseModSelectNewTime}
              >
                เลือกเวลาใหม่
              </MuiButton>
            </div>
          </>
        </DialogReadMore>

        <DialogConfirm
          open={openModWaitingConfirm}
          title={"กรุณารอการยืนยัน\nแก้ไขนัดหมายจากโรงพยาบาล"}
          approveText="ยืนยัน"
          img={IMAGES.request_app}
          imgSize="7.6563rem"
          denyBasic={true}
          // callback
          onApprove={handleConfirmEdit}
          onDeny={handleCloseModWaitingConfirm}
        >
          <>
            <div style={{ color: COLORS.light_grey }}>
              การแก้ไขนัดหมายของท่านจะได้รับการยืนยัน ภายใน 24 ชั่วโมง
            </div>
            <div className={classes.sub_title}>วันเวลาในการนัดหมายของท่าน</div>

            <BoxTitle
              type="datetime"
              datetime={appointmentDatetime}
              style={{
                minHeight: "60px",
                width: "100%",
                marginBottom: "0.5rem",
              }}
            />
          </>
        </DialogConfirm>

        <ModBookingUnSuccess
          open={!!errorMessage}
          onClick={handleClearErrorMessage}
        />
      </div>
    </DoctorBanner>
  );
};

export default React.memo(SelectDatetime);
